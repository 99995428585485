import '@brightspace-ui/core/components/alert/alert.js';
import '@brightspace-ui/core/components/button/button-subtle.js';

import '../../../../app-link.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading1Styles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { linkStyles } from '@brightspace-ui/core/components/link/link.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import Activity from '../../../../../shared/models/activity/activity.js';
import { Application } from '../../../../../shared/models/application/index.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova.js';

export default class RegistrationDetails extends LocalizeNova((RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      accountConnectionEmail: { type: String },
      activity: { type: Object },
      application: { type: Object },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      heading2Styles,
      heading3Styles,
      linkStyles,
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }

        .alert-banner {
          margin-top: 18px;
        }

        .missing-connection {
          width: fit-content;
          max-width: none;
        }

        .connection-banner {
          display: flex;
          justify-content: flex-start;
          box-sizing: border-box;
          width: 660px;
          max-width: 100%;
          height: 78px;
          margin-top: 18px;
          padding-left: 30px;
          background: var(--d2l-color-sylvite);
          border: 1px solid var(--d2l-color-mica);
          border-radius: 6px;
        }

        .copy-button {
          margin-top: 18px;
          margin-right: 30px;
        }

        .registration-header {
          margin-top: 12px;
          margin-bottom: 0;
        }

        .help-message {
          margin-top: 12px;
        }

        .next-action {
          margin-top: 36px;
          margin-bottom: 0;
        }

        .account-connection-email {
          width: fit-content;
          margin-top: 24px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
`,
    ];
  }

  constructor() {
    super();
    this.accountConnectionEmail = '';
    this.activity = new Activity();
    this.application = new Application();
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  render() {
    const { state } = this.application.status.indicator;

    if (this._shouldHideComponent()) return nothing;

    if (state === 'alert') {
      return this._getBannerForAlertState(); // if app is cancelled, failed or withdrawn, show alert banner
    } else if (this.accountConnectionEmail) {
      return this._registrationDetailsTemplate(); // if email is provided, show normal details template
    } else {
      return this._missingAccountConnectionTemplate(); // if email is missing show alert banner with a link to support
    }
  }

  _alertStateBannerTemplate(text) {
    return html`
      <d2l-alert type="critical" class="alert-banner">
        ${text}
      </d2l-alert>`;
  }

  async _copyAccountConnectionEmail() {
    await navigator.clipboard.writeText(this.accountConnectionEmail);
    this.session.toast({ type: 'default', message: this.localize('view-application.registrationDetails.copied') });
  }

  _getBannerForAlertState() {
    const activityType = this.activity.type;
    const displayName = this.application.user.getDisplayName();

    switch (this.application.status.label) {
      case 'cancelled_before_registration':
      case 'cancelled_after_registration':
        return this._alertStateBannerTemplate(this.localize('view-application.registrationDetails.cancelled', { activityType }));
      case 'withdrawn':
        return this._alertStateBannerTemplate(this.localize('view-application.registrationDetails.withdrawn', { activityType, displayName }));
      case 'failed':
        return this._alertStateBannerTemplate(this.localize('view-application.registrationDetails.failed', { activityType, displayName }));
    }
  }

  _missingAccountConnectionTemplate() {
    const displayName = this.application.user.getDisplayName();

    return html`
      <d2l-alert type="critical" class="alert-banner missing-connection">
        ${this.localize('view-application.registrationDetails.connectionbanner', { displayName })}
        <app-link d2l-link href="/contact-support">
          ${this.localize('app-footer.help.link')}
        </app-link>
      </d2l-alert>
    `;
  }

  _registrationDetailsTemplate() {
    const displayName = this.application.user.getDisplayName();

    return html`
      <h2 class="d2l-heading-2 d2l-skeletize registration-header">
        ${this.localize('view-application.registrationDetails.header')}
      </h2>
      <p class="help-message">
        ${this.localize('view-application.registrationDetails.helpMessage', { displayName })}
      </p>
      ${this._templateConnectionBanner()}
      <p class="next-action">
        ${this.localize('view-application.registrationDetails.nextAction')}
      </p>
    `;
  }

  _shouldHideComponent() {
    return this.session?.tenant?.type !== 'provider'
      || this.activity?.hasTag('submitMyOwn');
  }

  _templateConnectionBanner() {
    return html`
    <div class="connection-banner">
      <p class="account-connection-email">${this.accountConnectionEmail}</p>
      <d2l-button-subtle
        class="copy-button"
        icon=tier1:copy
        text=${this.localize('view-application.registrationDetails.copy')}
        @click=${this._copyAccountConnectionEmail}>
      </d2l-button-subtle>
    </div>`;
  }

}

window.customElements.define('registration-details', RegistrationDetails);
