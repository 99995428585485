import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/icons/icon.js';
import { heading1Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { html, LitElement, nothing } from 'lit';
import { navigator as nav } from 'lit-element-router';

import { LocalizeNova } from '../../../../shared/mixins/localize-nova.js';
import { mapify } from '../../../../../shared/methods.js';
import Tenant from '../../../../../shared/models/tenant/index.js';

import '../../../../main/components/applications/application-table/application-table.js';

import { Application, statuses } from '../../../../../shared/models/application/index.js';

export default class ViewApplications extends SkeletonMixin(LocalizeNova(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      _applications: { type: Array },
      _aggregations: { type: Object },
      _providers: { type: Array },
      _employers: { type: Array },
      _tenantIdFilter: { type: String },
      _totalApplications: { type: Number },
    };
  }

  static get styles() {
    return [
      super.styles,
      heading1Styles,
    ];
  }

  constructor() {
    super();
    this._employers = [];
    this._providers = [];
    this._applications = [];
    this._filters = {};
    this._tenantIdFilter = undefined;
    this.skeleton = true;
  }

  async connectedCallback() {
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');

    // By default, size is set to 0 to avoid unecessary fetching
    const searchOptions = { includeAggregations: true, size: 0 };
    if (!this.session?.user?.hasSetting('requestFilters')) {
      searchOptions.from = 0;
      searchOptions.size = 10;
    }

    // We only want to include aggregations on the first search. This is where the filter values come from, so if we update
    // the filter values then our aggregations would become useless.
    const searchResults = await this.client.searchApplications(searchOptions);

    const providersPromise = this.session.tenant.type === 'provider'
      ? [this.session.tenant]
      : this.client.listTenants('provider'); // viewer is an employer/admin

    const employersPromise = this.session.tenant.type === 'employer'
      ? [this.session.tenant]
      : this.client.listTenants('employer'); // viewer is a provider/admin
    [this._providers, this._employers] = await Promise.all([providersPromise, employersPromise]);

    this._applications = searchResults.applications.hits.map(a => new Application(a));
    this._aggregations = searchResults.aggregations;
    this._totalApplications = searchResults.applications.total.value;

    this._convertedAmountsAdded = await this._addConvertedAmountsToApps();
    this._setStatusOneOfTwoApproved();
    super.connectedCallback();
  }

  render() {
    return this._convertedAmountsAdded ? html`
      <span>
        <application-table
          .skeleton="${this.skeleton}"
          heading=${this.localize('view-applications.title')}
          .applications=${this._applications}
          .aggregations=${this._aggregations}
          .totalApplications="${this._totalApplications}"
          @application-table-filter-change="${this._handleFilterChange}"
          @application-table-page-change="${this._handlePageChange}"
          @application-table-sort="${this._handleSortChange}"
        ></application-table>
      </span>
    ` : nothing;
  }

  async _handlePageChange(e) {
    const pageSize = 10;
    const { pageNumber } = e.detail;
    await this._updateApplications((pageNumber - 1) * pageSize, pageSize);
  }

  async _handleFilterChange(e) {
    this._filters = e.detail.filters;
    await this._updateApplications(0, 10);
  }

  async _handleSortChange(e) {
    this._sort = e.detail.sort;
    await this._updateApplications(0, 10);
  }

  async _updateApplications(from, size) {
    this.skeleton = true;
    const searchResults = await this.client.searchApplications({
      from,
      size,
      filters: this._filters,
      sort: this._sort,
    });
    this._totalApplications = searchResults.applications.total.value;
    this._applications = searchResults.applications.hits.map(a => new Application(a));
    this._convertedAmountsAdded = await this._addConvertedAmountsToApps();
    this._setStatusOneOfTwoApproved();
    this.skeleton = false;
  }

  async _addConvertedAmountsToApps() {
    const tenantsMap = mapify(([...this._employers, ...this._providers]).map(tenant => new Tenant(tenant)));

    for (const app of this._applications) {

      // For each app, check if conversion rate is needed and promise to get it
      const provider = tenantsMap[app.activity.provider];
      app.providerCurrency = provider?.operatingCurrency.toUpperCase();

      const employer = tenantsMap[app.tenantId];
      app.employerCurrency = employer.operatingCurrency.toUpperCase();

      if (app.isApproved && !app.tempApprovedAmount.isEmpty()) {
        app.approvedAmountInProviderCurrency = app.tempApprovedAmount
          .inOriginalCurrency()
          .formatAsDecimal(this.session.settings.language);
      } else {
        app.approvedAmountInProviderCurrency = '';
      }

    }
    return true;
  }

  _isUserRequestApprover(sessionUser, application) {
    const persona = sessionUser.getPersona().split('_')[1];
    const approverType = persona === 'sponsor' ? persona : 'manager';
    return application[`${approverType}Approval`].approvalState === 'approved';
  }

  _setStatusOneOfTwoApproved() {
    const { isHybridSession, user: sessionUser } = this.session;
    this._applications.forEach(app => {
      const { isApprovedByFirstApproverOnly, userId, status: appStatus } = app;
      const isMyApplication = userId === sessionUser?.userId;
      const isOneOfTwoApproved = isHybridSession && isApprovedByFirstApproverOnly && (this._isUserRequestApprover(sessionUser, app) || isMyApplication);
      if (isOneOfTwoApproved) {
        app.status = { ...appStatus, ...statuses.ONE_OF_TWO_APPROVED };
      }
    });
  }
}

window.customElements.define('view-applications', ViewApplications);
