import { html, LitElement } from 'lit';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../../../shared/mixins/localize-nova.js';

import '../../../../main/components/applications/submit-own-request-form/submit-own-request-form.js';

export default class SubmitOwnRequest extends LocalizeNova(RequesterMixin(nav(LitElement))) {

  static get properties() {
    return {
      _courseStartDate: { type: String, attribute: false },
      _employer: { type: Object, attribute: false },
      _errors: { type: Array, attribute: false },
      _selectedCourseType: { type: String, attribute: false },
    };
  }

  constructor() {
    super();
    this.skeleton = true;

    this._errors = [];
    this._requestedApprover = {};
    this._selectedCourseType = 'course';
    this._courseStartDate = '';
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  async firstUpdated() {
    this.skeleton = true;
    this._employer = await this.client.fetchTenant(this.session.tenantId);
    this._determineRequestedApprover();
    this.skeleton = false;
  }

  render() {
    if (!this.session.tenant?.tags?.hasTag('ownRequestSubmit')) {
      this.navigate('/requests');
    }

    if (this.skeleton) return html`${this.localize('submit-own-request.loading')}`;
    return html`
      <submit-own-request-form .employer=${this._employer}></submit-own-request-form>
    `;
  }

  _determineRequestedApprover() {
    const ra = this._employer.determineRequestedApprover(this.session.user);
    if (!ra.id) {
      if (this.session.user.tenantType === 'employer') {
        this.session.toast({ message: `${this.localize('apply-activity.noManager.toast')}` });
      } else { // It's a provider or admin
        this.session.toast({ message: `${this.localize('apply-activity.error.cantApply')}` });
      }
    }
  }
}

window.customElements.define('submit-own-request', SubmitOwnRequest);
