import '@brightspace-ui/core/components/alert/alert.js';
import '@brightspace-ui-labs/autocomplete/autocomplete.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/tooltip/tooltip.js';
import '../../components/general/nova-card.js';
import '../../components/general/get-user-details.js';

import { css, html, LitElement, nothing } from 'lit';

import { checkboxStyles } from '@brightspace-ui/core/components/inputs/input-checkbox.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { linkStyles } from '@brightspace-ui/core/components/link/link.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { createMarketoForm, LOGIN_FORM_ID, submitMarketoForm } from '../../lib/marketo.js';
import { LocalizeNova } from '../../mixins/localize-nova.js';
import { novaCardStyles } from '../../styles/nova-card.js';
import { submitGTMForm } from '../../lib/gtm.js';

export default class LoginMarketing extends LocalizeNova(RequesterMixin(LitElement)) {

  static get properties() {
    return {
      _loginLink: { type: String, reflect: false },
      _showRegisterForm: { type: Boolean },
      _suggestions: { type: Array },
      _names: { type: Object },
      _hasErrors: { type: Object },
      errors: { type: Array, reflect: false },
    };
  }

  static get styles() {
    return [
      linkStyles,
      inputLabelStyles,
      checkboxStyles,
      novaCardStyles,
      selectStyles,
      css`
        :host {
          display: block;
        }

        .welcome-content {
          z-index: 1;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }

        .hidden-label {
          display: none;
        }

        .marketing-agreement-text {
          display: inline-block;
          margin-left: 0.5rem;
          color: var(--d2l-color-ferrite);
          font-weight: 400;
          font-size: 0.8rem;
          white-space: normal;
          vertical-align: top;
        }

        .checkbox-wrapper {
          display: grid;
          grid-template-columns: 23px 1fr;
          padding-bottom: 10px;
        }

        .welcome-text {
          margin-right: 130px;
          color: #ffffff;
        }

        .showcase-welcome-text {
          max-width: 530px;
          margin-top: 10px;
          margin-right: 100px;
          color: #000000;
        }

        .showcase-welcome-text a {
          color: #000000;
        }

        .showcase-login-header {
          padding-top: 20px;
          color: #000000;
          line-height: 45px;
        }

        .login-header {
          padding-top: 30px;
        }

        .login-message {
          place-items: center;
        }

        .login-form {
          max-width: 350px;
        }

        .disclaimer {
          font-size: 0.7rem;
        }

        h2 {
          margin-bottom: 0;
        }

        .company-wrapper {
          display: grid;
        }

        .login-input {
          display: block;
          width: 100%;
          max-width: 350px;
          padding-bottom: 12px;
        }

        d2l-form[invalid] > :first-child {
          margin-top: 12px;
        }

        .login-button {
          padding-top: 10px;
        }

        @media (max-width: 1080px) {
          .welcome-content {
            justify-content: center;
          }

          .showcase-welcome-text {
            margin-right: 70px;
          }
        }

        @media (max-width: 950px) {
          .welcome-content {
            justify-content: center;
          }

          .welcome-text {
            margin-right: 0;
          }
          .showcase-welcome-text {
            margin-right: 0;
          }
        }
        @media (max-width: 615px) {
          .showcase-login-header {
            padding-top: 20px;
            line-height: 45px;
          }
        }

`,
    ];
  }

  constructor() {
    super();
    this.errors = [];
    this._user = {};
    this._names = {};
    this._hasErrors = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    createMarketoForm(LOGIN_FORM_ID);
    this.requestUpdate();
  }

  render() {
    return html`
      <div class="welcome-content">
          ${this._marketingTemplate}
      </div>
    `;
  }

  async updateUserAttribute(e) {
    this._user[e.target.id] = e.target.value;

    if (e.target.id === 'marketingAgreement') this.requestUpdate();
  }

  get _marketingTemplate() {
    const privacyPolicyLink = this.localize('login.general.privacyPolicy', { link: this.l10nTags.d2lLink('https://www.d2l.com/legal/privacy/') });
    const previouslyAgreed = document.cookie.indexOf('_mkto_trk=') >= 0;
    const tooltip = !this._isMarketingAgreed()
      ? html`
        <d2l-tooltip align="start" state="error" for="form1-submit">
          ${this.localize('login.marketing.marketingAgreement.tooltip')}
        </d2l-tooltip>`
      : nothing;

    const loginTemplate = html`
      <nova-card class="login-message">
        <h2 slot="header" class="d2l-heading-2">${this.localize('login.marketing.form1.title')}</h2>
        <div slot="primary" class="login-form">
          <d2l-form id="marketing-login" @keydown=${this._checkMarketingInfo} ?invalid=${this._hasErrors}>
            <d2l-input-text id="email" class="login-input" autocomplete="on" name="email" type="email" @blur=${this.updateUserAttribute} label="${this.localize('login.marketing.workEmail')}" required></d2l-input-text>
            <div class="checkbox-wrapper">
              <input id="marketingAgreement"
                    name="marketingAgreement"
                    .checked="${previouslyAgreed}"
                    class="login-input d2l-input-checkbox"
                    type="checkbox"
                    @change=${this.updateUserAttribute}
                    required>
              <div class="marketing-agreement-text">${this.localize('login.marketing.marketingAgreement')}</div>
              <label for="marketingAgreement" class="hidden-label">${this.localize('login.marketing.marketingAgreement.required')}</label>
            </div>
            <d2l-button primary id="form1-submit" class="login-button" @click="${this._checkMarketingInfo}" type="submit" ?disabled=${!this._isMarketingAgreed()}>${this.localize('login.marketing.form3.submit')}</d2l-button>
            ${tooltip}
          </d2l-form>
          <p class="disclaimer">${privacyPolicyLink}</p>
        </div>
      </nova-card>
    `;

    const registerTemplate = html`
      <get-user-details email="${this._user.email}" component-size type="marketing"></get-user-details>
    `;

    const d2lForBusinessLink = {
      link: this.l10nTags.link('https://www.d2l.com/solutions/corporate/'),
    };

    const loginTextTemplate = html`
      <h1 class="showcase-login-header">${this.localize('login.marketing.form3.introTextHeader')}</h1>
      <p>${this.localize('login.marketing.form3.introText.1')}</p>
      <p>${this.localize('login.marketing.form3.introText.2', d2lForBusinessLink)}</p>
    `;

    const registerTextTemplate = html`
      <h1 class="login-header">${this.localize('login.marketing.form2.introTextHeader')}</h1>
      <p>${this.localize('login.marketing.form2.introText')}</p>
    `;

    return html`
      <div class="showcase-welcome-text">
        <img alt="client-logo" height="100px" src="../../../assets/img/D2L-preferred-Black.svg">
        ${this._showRegisterForm ? registerTextTemplate : loginTextTemplate }
      </div>
      ${this._showRegisterForm ? registerTemplate : loginTemplate }
    `;
  }

  async _checkMarketingInfo(e) {
    const valid = await this._isFormValid('marketing-login', e);
    if (!valid) return;

    this._user = this._extractUserFromForm();
    const entitlements = await this.client.getMarketingInfo(this._user.email);
    if (entitlements.length === 0) {
      this._showRegisterForm = true;
      return;
    }

    // Since we know the user has previously submitted marketing info, then login.
    this._user = this._extractUserFromForm();
    this._loginLink = await this.client.createLoginLink({ user: this._user, relayState: `${window.location.pathname}${window.location.search}` });

    await submitGTMForm();
    await submitMarketoForm(this._user, this._loginLink.magicLink, LOGIN_FORM_ID);
  }

  _extractUserFromForm() {
    const userId = this.shadowRoot.getElementById('email').value;
    const email = this.shadowRoot.getElementById('email').value;

    return { userId, email };
  }

  async _isFormValid(formId, e) {
    const { key } = e;
    this._hasErrors = false;
    if (!key || key === 'Enter') {
      const form = this.shadowRoot.getElementById(formId);
      const resp = await form.validate();
      const isValid = resp === undefined || resp.size <= 0;
      this._hasErrors = !isValid;
      return isValid;
    }
    return false;
  }

  _isMarketingAgreed() {
    const el = this.shadowRoot.getElementById('marketingAgreement');
    return !!el?.checked;
  }

}

window.customElements.define('login-marketing', LoginMarketing);
