import '../../../app-link.js';

import { css, html, LitElement } from 'lit';

import { bodySmallStyles, heading1Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { linkStyles } from '@brightspace-ui/core/components/link/link.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../mixins/localize-nova.js';

export default class ThankyouSignup extends LocalizeNova(RequesterMixin(LitElement)) {

  static get properties() {
    return {
      query: { type: Object },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      heading1Styles,
      linkStyles,
      css`
        :host {
          display: block;
          height: 100vh;
          overflow-y: auto;
          background: #f7fafe;
          background-image: url(../../../assets/img/bg-waves.svg);
          background-position: 0 0;
          background-size: contain;
        }

        .splash {
          display: grid;
          box-sizing: border-box;
          width: 100%;
          min-height: 100%;
          padding: 1rem;
          place-items: center;
        }

        .card-content {
          display: grid;
          max-width: 559px;
          text-align: center;
        }

        .nova-card {
          border: none;
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
        }

        .thankyou-message {
          margin: 0 30px 20px;
        }

`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
    this._resendLinkClickable = true;
  }

  async firstUpdated() {
  }

  async handleResendEmail() {
    if (this._resendLinkClickable) {
      const auth0Id = this.session.user.getExtraAttribute('id');
      const verificationId = auth0Id;
      await this.client.resendVerificationEmail(verificationId);
      this.session.toast({ type: 'default', message: this.localize('thankyou-signup-email-resent', { email: this.session.user.email }) });

      // Disable the link for 5 seconds
      this._resendLinkClickable = false;
      setTimeout(() => {
        this._resendLinkClickable = true;
      }, 5000);
    }
  }

  render() {
    return html`
      <div class="splash">
        <nova-card class="nova-card">
          <div class="card-content" slot="primary">
            <h2 class="d2l-heading-2">${this.localize('thankyou-signup.title')}</h2>
            <p class="thankyou-message d2l-body-standard">${this.localize('thankyou-signup.body', { userEmail: this.session.user.email, linebreak: () => html`<br />` })}</p>
            <div class="d2l-body-small">${this.localize('thankyou-signup.emailNotRecieved', {
    resendLink: this.l10nTags.d2lOnClick(() => this.handleResendEmail()),
    contactLink: this.l10nTags.d2lAppLink('/contact-support'),
  })}
          </div>
        </nova-card>
      </div>
    `;
  }

}

window.customElements.define('thankyou-signup', ThankyouSignup);
