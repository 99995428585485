import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/inputs/input-number.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/htmleditor/htmleditor.js';

import '../../../shared/components/tooltip/nova-tooltip.js';
import './provider-finance-fields.js';

import { css, html, LitElement, nothing } from 'lit';
import { bodySmallStyles } from '@brightspace-ui/core/components/typography/styles.js';
import DOMPurify from 'dompurify';
import { ifDefined } from 'lit/directives/if-defined.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { navigator as nav } from 'lit-element-router';
import { radioStyles } from '@brightspace-ui/core/components/inputs/input-radio-styles.js';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { createModel } from '../../../../shared/models/models.js';
import { LocalizeNova } from '../../../shared/mixins/localize-nova.js';
import { NovaFormMixin } from '../../../shared/mixins/nova-form-mixin.js';

import { DEFAULT_DAYS_UNTIL_INVOICE_DUE } from '../../../../shared/models/schema/tenant/index.js';
import { LEARNER_OPTIONS } from '../../../../shared/constants.js';

export default class ManageGeneral extends NovaFormMixin(LocalizeNova(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      createMode: { type: Boolean, reflect: false, attribute: 'create-mode' },
      tenant: { type: Object, reflect: false },
      files: { type: Object, reflect: false },
      logoPreview: { type: String, reflect: false },
      emailPreview: { type: String, reflect: false },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      inputLabelStyles,
      radioStyles,
      selectStyles,
      css`
        d2l-htmleditor {
          padding: 0.75rem 0;
        }

        d2l-input-text, d2l-input-number {
          margin: 0;
          padding-bottom: 1.5rem;
        }

        .select-wrapper {
          padding-bottom: 1.5rem;
        }

        .submit-button {
          padding-top: 10px;
        }

        .image-preview {
          width: 50%;
          max-width: 350px;
          margin-right: 10px;
          object-fit: contain;
        }

        .hidden {
          display: none;
        }

        .preview-placeholder {
          padding: 10px 40px;
          color: grey;
          font-size: 1rem;
          border: thin dashed grey;
          border-radius: 15px;
        }

        .logo-uploader {
          display: flex;
          padding-bottom: 1.5rem;
        }

        .upload-btn-wrapper {
          position: relative;
          display: inline-block;
          padding-top: 10px;
          overflow: hidden;
        }

        .btn {
          padding: 8px 20px;
          color: gray;
          font-weight: bold;
          font-size: 20px;
          background-color: white;
          border: 2px solid gray;
          border-radius: 8px;
        }

        .upload-btn-wrapper input[type=file] {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 100px;
          opacity: 0;
        }
`,
    ];
  }

  constructor() {
    super();
    this.logoPreview = undefined;
    this.emailPreview = undefined;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  firstUpdated() {
    // Initialize NovaFormMixin properties
    this.hideFormErrorSummary = false;
    this.showErrorToast = true;
    this.files = { logo: null, email: null };
    this.logoPreview = this.tenant.imageUrl;
    this.emailPreview = this.tenant.imageUrlEmail;
  }

  render() {
    if (!this.tenant) return nothing;
    return html`
      <d2l-form @change=${this._changeValue}>

        <d2l-input-text
          id="name"
          value=${this.tenant.name}
          required
          label="Name"></d2l-input-text>

        <div style="display: flex;">
          <label for="logo" class="d2l-input-label d2l-skeletize">Logo Image</label>
          <span class="d2l-input-label">* (note: svg perform best for within Wave)</span>
        </div>
        <div class="logo-uploader">
          <img class="image-preview${this.logoPreview ? '' : ' hidden'}" id="logoPreview" height="60px" src=${this.logoPreview}>
          <div id="logo-uploader" class="preview-placeholder${this.logoPreview ? ' hidden' : ''}">Logo image preview</div>

          <div class="upload-btn-wrapper">
            <d2l-button-subtle
              icon="tier1:upload"
              text="Upload image"
            >
            <input type="file" id="logo" accept="image/png, image/jpeg, image/svg+xml, image/bmp" />
            </d2l-button-subtle>
          </div>
        </div>

        <div style="display: flex;">
          <label for="email" class="d2l-input-label d2l-skeletize">Email Logo Image</label>
          <span class="d2l-input-label">* (note: email logos must be one of .png, .jpg, .jpeg)</span>
        </div>
        <div class="logo-uploader">
          <img class="image-preview${this.emailPreview ? '' : ' hidden'}" id="emailPreview" height="60px" src=${this.emailPreview}>
          <div id="email-uploader" class="preview-placeholder${this.emailPreview ? ' hidden' : ''}">Logo image preview</div>

          <div class="upload-btn-wrapper">
            <d2l-button-subtle
              icon="tier1:upload"
              text="Upload image"
            >
            <input type="file" id="email" accept=".png, .jpg, .jpeg" />
            </d2l-button-subtle>
          </div>
        </div>

        <d2l-input-text
          id="accentColour"
          value=${ifDefined(this.tenant.accentColour)}
          label="${this.localize('manage-general.label.accentColour')}"></d2l-input-text>

        ${this.tenant.type === 'employer' ? html`
          <d2l-input-text
            id="programPolicy"
            value=${ifDefined(this.tenant.programPolicy)}
            label="${this.localize('manage-general.label.programPolicy')}"></d2l-input-text>` : nothing}

        ${this.tenant.type !== 'admin' ? html`
          <d2l-input-text
            id="financeEmail"
            value="${ifDefined(this.tenant.financeEmail)}"
            required
            type="email"
            label="${this.localize('manage-general.label.financeEmail')}">
          </d2l-input-text>
        ` : nothing}

        ${this.tenant.type === 'provider' ? html`
          <d2l-input-text
          id="studentSupportEmail"
          value="${ifDefined(this.tenant.studentSupportEmail)}"
          type="email"
          label="${this.localize('manage-general.label.studentSupportEmail')}">
        </d2l-input-text>
        ` : nothing}

        ${this.tenant.type !== 'admin' ? html`
          <d2l-input-number
            min="1"
            max-fraction-digits="0"
            id="daysUntilInvoiceDue"
            value="${this.tenant?.daysUntilInvoiceDue ?? DEFAULT_DAYS_UNTIL_INVOICE_DUE}"
            required
            label="${this.localize('manage-general.label.daysUntilInvoiceDue')}">
          </d2l-input-number>
        ` : nothing}

        ${this.tenant.type === 'employer' ? html`
          <div class="select-wrapper">
            <label for="learnerTerminology" class="d2l-input-label d2l-input-label-required">${this.localize('manage-general.learnerTerminology')}</label>
            <select
            id="learnerTerminology"
            name="learnerTerminology"
            class="d2l-input-select"
            required>
            ${repeat(LEARNER_OPTIONS, option => html`
              <option
                id="${option}"
                value="${option}"
                ?selected=${this.tenant.learnerTerminology === option}>
                ${option}
              </option>`)}
            </select>
          </div>
        ` : nothing}

        ${this.tenant.type === 'provider' ? html`
          ${this.createMode ? html`
            <provider-finance-fields
              .tenant=${this.tenant}
              @update-finance-fields=${this._dispatchUpdateTenant}></provider-finance-fields>
          ` : nothing}

          <d2l-input-number
            id="registrationTime"
            value=${this.tenant.registrationTime}
            required
            label="Estimated registration time (in business days)"
            min="0"
            max-fraction-digits="0"></d2l-input-number>
        ` : nothing}

        <d2l-input-checkbox id="testData" ?checked="${this.tenant.hasTag('testData')}">
          Test data
        </d2l-input-checkbox>

        ${this.tenant.type === 'provider' ? html`
          <d2l-input-checkbox id="accountConnection" ?checked="${this.tenant.hasTag('accountConnection')}">
            Enable and Require Account Connection
          </d2l-input-checkbox>
        ` : nothing}

        ${(this.tenant.hasTag('accountConnection')) ? html`
          <d2l-input-text
            id="accountCreationUrl"
            required
            value=${ifDefined(this.tenant.accountCreationUrl)}
            label="Account Connection URL (must include https:// prefix)"></d2l-input-text>
        ` : nothing}

        <d2l-input-checkbox id="collectUserInfoOnCreate" ?checked=${this.tenant.hasTag('collectUserInfoOnCreate')}>
          Collect additional user information upon account creation (e.g. company, country, etc. ).
        </d2l-input-checkbox>

        <d2l-input-checkbox id="requireUserEmails" ?checked="${this.tenant.hasTag('requireUserEmails')}">
          ${this.localize('manage-general.tags.collectEmailOnLogin')}
        </d2l-input-checkbox>

        ${this.tenant.type === 'employer' ? html`
          <d2l-input-checkbox id="ownRequestSubmit" ?checked="${this.tenant.hasTag('ownRequestSubmit')}">
            ${this.localize('manage-general.tags.ownRequestSubmit')}
          </d2l-input-checkbox>
          <d2l-tooltip disable-focus-lock position="top" for="ownRequestSubmit" align="start">
            ${this.localize('manage-general.tags.ownRequestSubmitTooltip')}
          </d2l-tooltip>
          <d2l-input-checkbox id="hideFastToCompleteStream" ?checked="${this.tenant.hasTag('hideFastToCompleteStream')}">
            ${this.localize('manage-general.tags.hideFastToCompleteStream', { streamName: this.localize('streams.property.fastToComplete') })}
          </d2l-input-checkbox>

          <d2l-input-checkbox id="allowEmailEdit" ?checked="${this.tenant.hasTag('allowEmailEdit')}">
          ${this.localize('manage-general.tags.allowEditableEmail')}
        </d2l-input-checkbox>

          <d2l-input-checkbox id="brightspaceInstance" ?checked="${this.tenant.hasTag('brightspaceInstance')}">
            ${this.localize('manage-general.tags.enableLinkToBrightspace')}
          </d2l-input-checkbox>
        ` : nothing}
        ${this.tenant.hasTag('brightspaceInstance') ? html`
          <d2l-input-text
          id="brightspaceName"
          maxlength=35
          value="${this.tenant?.brightspaceName}"
          required
          label="${this.localize('manage-general.brightspace.name')}">
        </d2l-input-text>

        <d2l-input-text
          id="brightspaceURL"
          type="url"
          value="${this.tenant?.brightspaceURL}"
          required
          label="${this.localize('manage-general.brightspace.url')}">
          </d2l-input-text>
        ` : nothing}

        <d2l-input-checkbox id="paymentHandledByProvider" ?checked="${this.tenant.hasTag('paymentHandledByProvider')}">
            ${this.localize('manage-general.tags.paymentHandledByProvider')}
        </d2l-input-checkbox>

        <d2l-input-checkbox id="automaticCatalogOptOut" ?checked="${this.tenant.hasTag('automaticCatalogOptOut')}">
            ${this.localize('manage-general.tags.automaticCatalogOptOut')}
        </d2l-input-checkbox>

        <d2l-htmleditor id="notes"
          label="${this.localize('manage-general.notes')}"
          html="${DOMPurify.sanitize(this.tenant.notes)}"
          @d2l-htmleditor-update="${this._changeValue}"
          source-editable>
        </d2l-htmleditor>

        <div id="submit-general" class="submit-button">
          <d2l-button @click=${this._saveGeneral} primary>
            ${this.localize('manage-general.buttonSave')}
          </d2l-button>
        </div>
      </d2l-form>
    `;
  }

  _changeValue(e) {
    if ((e.target.id === 'logo' || e.target.id === 'email') && e.target.files.length > 0) {
      this.files[e.target.id] = e.target.files[0];

      if (e.target.id === 'logo') this.logoPreview = URL.createObjectURL(e.target.files[0]);
      if (e.target.id === 'email') this.emailPreview = URL.createObjectURL(e.target.files[0]);
      this.requestUpdate();
    } else if (e.target.checked !== undefined) {
      this.tenant.tags.setTag(e.target.id, e.target.checked);
    } else if (e.target.hasAttribute('html')) {
      this.tenant[e.target.id] = e.target.html;
    } else if (e.target.id === 'daysUntilInvoiceDue') {
      this.tenant[e.target.id] = parseInt(e.target.value);
    } else {
      this.tenant[e.target.id] = e.target.value;
    }

    this.updateFormErrorSummary();
    this._dispatchUpdateTenant();
  }

  _dispatchUpdateTenant() {
    this.dispatchEvent(new CustomEvent('update-tenant', { detail: { tenant: this.tenant } }));
  }

  async _sendLogoFilesToS3() {
    const data = new FormData();
    if (this.files?.email) data.append('email', this.files.email);
    if (this.files?.logo) data.append('logo', this.files.logo);
    const logoKeys = await this.client.updateTenantImage(this.tenant.id, data);

    if (this.files?.email) this.tenant.imageUrlEmail = logoKeys.email;
    if (this.files?.logo) this.tenant.imageUrl = logoKeys.logo;
  }

  async _saveGeneral() {
    if (!this.logoPreview) {
      this.shadowRoot.getElementById('logo').required = true;
    }
    if (!this.emailPreview) {
      this.shadowRoot.getElementById('email').required = true;
    }
    const isFormValidated = await this.isFormValidated();
    if (!isFormValidated) return;

    if (this.createMode) {
      // When we create a new tenant, automatically turn-on the Amplitude feature flag, so we don't forget.
      this.tenant.features = { amplitude: true };
      this.tenant = createModel(await this.client.createTenant(this.tenant));

      // send the logo files to s3
      if (this.files?.email || this.files?.logo) {
        await this._sendLogoFilesToS3();
        this.tenant = createModel(await this.client.updateGeneral(this.tenant));
      }

      this.navigate(`/tenants/${this.tenant.id}/edit`);
    } else {
      const toast = { message: 'Tenant general info saved', type: 'default' };

      // send the logo files to s3
      if (this.files?.email || this.files?.logo) {
        await this._sendLogoFilesToS3();
      }

      try {
        this.tenant = createModel(await this.client.updateGeneral(this.tenant));
      } catch (e) {
        toast.message = await e.text();
        toast.type = 'critical';
      }

      this.session.toast(toast);
    }
  }
}

window.customElements.define('manage-general', ManageGeneral);
