import '../../../app-link.js';

import { css, html, LitElement } from 'lit';

import '@brightspace-ui/core/components/button/button.js';
import { bodySmallStyles, heading1Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { buttonStyles } from '@brightspace-ui/core/components/button/button-styles.js';
import { linkStyles } from '@brightspace-ui/core/components/link/link.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../mixins/localize-nova.js';
import { NovaNavMixin } from '../../mixins/nova-nav.js';

export default class AuthError extends LocalizeNova(RequesterMixin(NovaNavMixin(LitElement))) {

  static get properties() {
    return {
      query: { type: Object },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      buttonStyles,
      heading1Styles,
      linkStyles,
      css`
        :host {
          display: block;
          height: 100vh;
          overflow-y: auto;
          background: #f7fafe;
          background-image: url(../../../assets/img/bg-waves.svg);
          background-position: 0 0;
          background-size: contain;
        }

        .splash {
          display: grid;
          box-sizing: border-box;
          width: 100%;
          min-height: 100%;
          padding: 1rem;
          place-items: center;
        }

        .card-content {
          display: grid;
          max-width: 559px;
          text-align: center;
        }

        .tryagain-button {
          padding: 0.83em 30px;
        }

        .nova-card {
          border: none;
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
        }

        .error-message {
          margin: 0 30px 20px;
        }

`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
  }

  async handleLoginClick() {
    this.navigateWithoutHistory('/login');
  }

  render() {
    return html`
      <div class="splash">
        <nova-card class="nova-card">
          <div class="card-content" slot="primary">
            <h2 class="d2l-heading-2">${this.localize('auth-error.title')}</h2>
            <p class="error-message d2l-body-standard">${this.localize('auth-error.body', { userEmail: this.session.user.email })}</p>
            <d2l-button class="tryagain-button" primary type="button" @click=${this.handleLoginClick}>${this.localize('auth-error.button.label')}</d2l-button>
          </div>
        </nova-card>
      </div>
    `;
  }

}

window.customElements.define('auth-error', AuthError);
