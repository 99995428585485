import '@brightspace-ui/core/components/alert/alert.js';
import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui-labs/autocomplete/autocomplete.js';

import { css, html, LitElement, nothing } from 'lit';

import '../general/nova-card.js';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { classMap } from 'lit/directives/class-map.js';
import { repeat } from 'lit/directives/repeat.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

import { isEmail } from '../../../../shared/helpers/util.js';
import { LocalizeNova } from '../../mixins/localize-nova.js';
import { NovaFormMixin } from '../../mixins/nova-form-mixin.js';
import UserSession from '../../../../shared/models/user-session.js';

import { ALL_LANGUAGES, localizerReady, setLocalizer, SUPPORTED_LANG_KEYS } from '../../../../shared/l10n/localize.js';
import { FrontendLocalizer } from '../../l10n/frontend-localizer.js';
import { NovaNavMixin } from '../../mixins/nova-nav.js';

const DEFAULT_CONTENT = {
  en: '<h1 class="login-header"> Develop new skills and grow your career</h1><p>Find, request, and register for quality education opportunities that will help you reach your full potential.</p>',
  fr: '<h1 class="login-header"> Développez de nouvelles compétences et faites progresser votre carrière</h1><p>Inscrivez-vous à des formations de qualité qui vous aideront à atteindre votre plein potentiel.</p>',
};
export default class LoginMagicLink extends NovaFormMixin(LocalizeNova(RequesterMixin(NovaNavMixin(LitElement)))) {

  static get properties() {
    return {
      _loginLink: { type: String, reflect: false },
      _loginEmailSent: { type: Boolean, reflect: false },
      _formIsInvalid: { type: Boolean, reflect: false },
      customContent: { state: true },
      errors: { type: Array, reflect: false },
    };
  }

  static get styles() {
    return [
      css`
        :host {
          display: block;
        }

        .change-lang-button {
          margin-left: auto;
        }

        .welcome-content {
          display: flex;
          flex-flow: wrap;
          align-items: center;
        }

        .login-input {
          display: block;
          width: 350px;
        }

        h2 {
          display: flex;
          margin-bottom: 0;
        }

        .login-button {
          padding-top: 10px;
        }

        .email-error {
          margin-top: 12px;
        }

        .login-card-wrapper {
          display: flex;
          align-items: center;
          max-width: 473px;
          height: 100%;
        }

        .client-logo {
          max-width: 400px;
          max-height: 200px;
        }

        .login-header {
          padding-top: 20px;
          color: #000000;
          line-height: 45px;
        }

        .welcome-text {
          max-width: 575px;
          margin-top: 10px;
          margin-right: 40px;
          color: #000000;
        }

        .login-card {
          place-items: center;
          height: fit-content;
        }

        .welcome-text a {
          color: #000000;
        }

        .login-header-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap-reverse;
          justify-content: space-between;
        }

        @media (max-width: 1080px) {
          .welcome-content {
            justify-content: center;
          }
          .login-card-wrapper {
            margin-top: 24px;
          }

          .welcome-text {
            margin-right: 70px;
          }
        }

        @media (max-width: 950px) {
          .welcome-content {
            justify-content: center;
          }

          .welcome-text {
            margin-right: 0;
          }
        }

        @media (max-width: 615px) {
          .login-header {
            padding-top: 20px;
            line-height: 45px;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.errors = [];
    this._languageKeys = SUPPORTED_LANG_KEYS;
    this.language = (navigator.language || navigator.userLanguage).split('-')[0];
    if (!this._languageKeys.some(lang => this.language === lang)) {
      this.language = 'en';
    }
    this.customContent = DEFAULT_CONTENT[this.language];
    this._user = new UserSession();
  }

  async firstUpdated() {
    await this.updateCustomContent();
  }

  async updateCustomContent() {
    const content = await this.client.getCustomMagicLinkContent(this.session.tenantId, this.language);
    if (content?.value?.length > 0) {
      this.customContent = content.value[0].copy;
    } else {
      this.customContent = DEFAULT_CONTENT[this.language] || DEFAULT_CONTENT.en;
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  get _languageSelectorTemplate() {
    return html`
      <d2l-dropdown-button-subtle class="change-lang-button" text="${this.localize(`activity.instructionLang.${this.language}`)}">
        <d2l-dropdown-menu>
          <d2l-menu label="${this.localize('general.language')}">
            ${repeat(this._languageKeys, key => key, key => html`
              <d2l-menu-item text=${ALL_LANGUAGES[key]} data-language="${key}" @click=${this.setLang}></d2l-menu-item>
            `)}
          </d2l-menu>
        </d2l-dropdown-menu>
      </d2l-dropdown-button-subtle>
    `;
  }

  updateUserAttribute(e) {
    this._user[e.target.id] = e.target.value;
  }

  get _hasErrors() {
    return this.errors.length > 0;
  }

  render() {
    const { imageUrl } = this.session.appDetails;

    const inputClassMap = {
      'email-error': this._formIsInvalid,
    };

    // Message that appears after the user requests for the magic link.
    const submittedTemplate = html`
      ${this.localize('login.magiclink.submitted.1', { email: this._user.getEmail() })}<br/>
      ${this.localize('login.magiclink.submitted.2')}
    `;

    // Main content for the login box.
    const loginTemplate = html`
      ${this._hasErrors > 0 ? html`
          <d2l-alert type="critical">
            ${this.errors.map(error => html`
            ${error}
            `)}
          </d2l-alert>
          ` : nothing}
      <d2l-form id="login" @keydown=${this._login}>
        <d2l-input-text required id="email" type="email" class="${classMap(inputClassMap)}" autocomplete="on" name="email" @change=${this.updateUserAttribute} label="${this.localize('login.magiclink.emailInput')}"></d2l-input-text>
        <d2l-button primary class="login-button" @click="${this._login}">${this.localize('login.magiclink.sendLoginLink')}</d2l-button>
      </d2l-form>
    `;

    const baseTemplate = this._loginEmailSent ? submittedTemplate : loginTemplate;

    // Both views have the same headers (e.g. client logo).
    const cardTemplate = html`
      <nova-card class="login-card">
        <div slot="header" class="login-header-container">
          <h2 class="d2l-heading-2">${this.localize('login.magiclink.loginHeader')}</h2>
          ${this._languageSelectorTemplate}
        </div>
        <div slot="primary">
          ${baseTemplate}
        </div>
      </nova-card>
    `;

    return html`
        <div class="welcome-content">
          <div class="welcome-text">
              <img class="client-logo" alt="" src="${imageUrl}">
              ${unsafeHTML(this.customContent)}
          </div>
          <div class="login-card-wrapper">
            ${cardTemplate}
          </div>
        </div>
    `;
  }

  async setLang(e) {
    this.language = e.target.getAttribute('data-language');
    await this.updateCustomContent();
    setLocalizer(this.language, FrontendLocalizer);
    await localizerReady();
    this.update();
  }

  _getEmailFromForm() {
    const email = this.shadowRoot.getElementById('email').value;
    if (!isEmail(email)) {
      this.errors = [this.localize('login.magiclink.invalidEmail')];
      this._formIsInvalid = true;
      return undefined;
    }
    return email;
  }

  /**
   * This should return true if the enter key was pressed, or no key at all (which means the submit button was clicked).
   */
  _isSubmitted(e) {
    const { key } = e;
    return !key || key === 'Enter';
  }

  async _login(e) {
    if (!this._isSubmitted(e)) return;

    const email = this._getEmailFromForm(e);
    if (!email) return;
    this._user = new UserSession({ email });

    try {
      this._loginLink = await this.client.createLoginLink({
        user: this._user,
        relayState: this.session.relayState,
      });
      this._loginEmailSent = true;

      if (this._loginLink?.magicLink) {
        // If the magic link url is passed to us, use it to navigate.
        const url = new URL(this._loginLink.magicLink);
        const pathAndParams = `${url.pathname}${url.search}`;
        this.navigateWithoutHistory(pathAndParams);
      }
    } catch (err) {
      this.errors = [this.localize('login.magiclink.invalidEmail')];
      this._formIsInvalid = true;
    }
  }

}

window.customElements.define('login-magic-link', LoginMagicLink);
