import '@brightspace-ui/core/components/colors/colors.js';
import '@brightspace-ui/core/components/status-indicator/status-indicator.js';

import { bodyCompactStyles, bodySmallStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { navigator as nav } from 'lit-element-router';
import { offscreenStyles } from '@brightspace-ui/core/components/offscreen/offscreen.js';
import { radioStyles } from '@brightspace-ui/core/components/inputs/input-radio-styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { ActiveLinkMixin } from '../../mixins/active-link/active-link-mixin.js';
import ActivitiesHelper from '../../../../shared/helpers/activities.js';
import { LocalizeNova } from '../../mixins/localize-nova.js';
import { localizeStartDate } from '../../../../shared/models/schema/activity/shared.js';

import '../../components/tooltip/nova-tooltip.js';

/**
 * A container element that provides specific layout for activity/program elements in Nova/Wave.
 * @info illustration - Slot for illustration content, such as course image (no actionable elements)
 * @info content - Slot for status indicator badge, primary text, and supporting meta-data (no actionable elements)
 * @slot actions - Slot for buttons and dropdown openers to be placed on the right side of the container
 */
class ActivitySmall extends LocalizeNova(ActiveLinkMixin(SkeletonMixin(nav(RequesterMixin(LitElement))))) {

  static get properties() {
    return {
      /**
       * Activity object pulled from data layer
       */
      activity: { type: Object },
      /**
       * ID of associated program activity
       */
      activityId: { type: String },
      /**
       * List of meta-data fields to be displayed in supporting-info slot of component
       */
      displayAttributes: { type: Array },
      /**
       * Indicates if component is in full size mode or reduced size mode
       */
      mini: { type: Boolean },
      /**
       * Indicates if associated activity is currently selected
       */
      selected: { type: Boolean, reflect: true },
      /**
       * Indicates if component is navigatible
       */
      noNav: { type: Boolean, attribute: 'no-nav' },
      /**
       * Accessible text for the component (will be announced when AT user focuses)
       */
      text: { type: String, reflect: true },
      _tooltipShowing: { type: Boolean, attribute: '_tooltip_showing', reflect: true },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodyCompactStyles,
      bodySmallStyles,
      offscreenStyles,
      radioStyles,
      css`
        :host {
          position: relative;
          z-index: 0;
          display: block;
          width: 100%;
          margin: 0.5rem 0;
          border-radius: 6px;
          transition: transform 300ms ease-out 50ms, box-shadow 0.2s;
        }

        .activity-card-container {
          display: flex;
          height: 6.2rem;
          background-color: var(--d2l-wave-white);
          border: 1px solid var(--d2l-color-mica);
          border-radius: 6px;
        }

        :host([hover]) .activity-card-container {
          border: 1px solid var(--d2l-color-celestine);
        }

        .activity-card-container-mini {
          height: unset;
          min-height: 4.1rem;
          max-height: 6.2rem;
        }

        .activity-card-link-container {
          display: flex;
          flex: 1 1 auto;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        .activity-card-illustration {
          position: relative;
          display: block;
          flex: 0 0 25%;
          min-width: 5rem;
          max-width: 10rem;
          overflow: hidden;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        .activity-card-illustration > img {
          position: absolute;
          width: 100%;
          min-height: 100%;
          object-fit: cover;
          object-position: left top;
        }

        .activity-card-content {
          flex: 2 1 75%;
          margin: auto 0.8rem;
        }

        .activity-card-badge {
          padding-bottom: 0.15rem;
          line-height: 0;
        }

        .activity-card-title {
          display: -webkit-box;
          margin: 0.25rem 0 0 0;
          padding: 0;
          overflow: hidden;
          color: var(--d2l-list-item-content-text-color);
          font-weight: 600;
          text-decoration: var(--d2l-list-item-content-text-decoration, none);
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .activity-card-title-mini {
          margin-top: 0;
        }

        .activity-card-supporting-info {
          margin: 0.25rem 0 0 0;
          padding: 0;
          overflow: hidden;
          color: var(--d2l-color-ferrite);
        }

        .bullet:last-of-type {
          display: none;
        }

        .activity-card-actions {
          /* this must be higher than footer z-index so dropdowns will be on top */
          z-index: 3;
          display: grid;
          flex-grow: 0;
          width: min-content;
          margin: auto;
        }

        .activity-card-actions ::slotted(*) {
          margin: auto 0.8rem;
        }

        :host([hover]) {
          box-shadow: 0 2px 14px 1px rgba(0, 0, 0, 0.06);
        }

        :host(:not([no-nav])[hover]) .activity-card-link-container .activity-card-title {
          color: var(--d2l-color-celestine);
          text-decoration: underline;
        }

        /* this is needed to ensure tooltip is not be clipped by adjacent cards */
        :host([_tooltip_showing]) {
          z-index: 1;
        }

        @media (max-width: 615px) {
          .activity-card-illustration {
            min-width: 4rem;
          }
        }

        @media (prefers-reduced-motion: reduce) {
          :host {
            transition: none;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.displayAttributes = ['delivery', 'formattedStartDate'];
    this.mini = false;
    this.text = 'Program';
    this.selected = false;
    this.href = '';
    this._tooltipShowing = false;
  }

  get ariaLabel() {
    const trimAttribute = attr => {
      return attr.replace('formatted', '');
    };
    const metaInfo = attr => {
      const notScheduledDate = this.localize('activity-small.notScheduledDescription');

      if (attr === 'formattedStartDate') {
        if (!this.activity.isScheduled() && this.disabled) {
          return notScheduledDate;
        } else if (this.activity.startDateType === 'date') {
          return localizeStartDate(ActivitiesHelper.getNextSessionDateAsString(this.activity.startDate));
        }
      }
      const normalAttribute = this.activity.getTranslatedValue(attr) || this.activity[attr];

      return normalAttribute;
    };
    const metaAttributeNames = this.displayAttributes.filter(attr => attr !== 'formattedStartDate' || this.activity.hasTag('allowRequest'));
    const metaAttributeValues = metaAttributeNames.map(metaInfo);

    const metaAttributeMap = [];
    for (let i = 0; i < metaAttributeNames.length; i++) {
      metaAttributeMap.push(`${trimAttribute(metaAttributeNames[i])}: ${metaAttributeValues[i]}`);
    }

    return `${this.activity.type} title: ${this.activity.title}, ${metaAttributeMap.join(', ')}`;
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
  }

  render() {
    if (!this.activity) return nothing;

    const cardContentClasses = {
      'activity-card-content' : true,
      'activity-card-content-mini' : this.mini,
    };

    const containerClasses = {
      'activity-card-container': true,
      'activity-card-container-selected' : this.selected,
      'activity-card-container-mini' : this.mini,
    };

    const linkContainerClasses = {
      'activity-card-link-container': true,
    };

    const titleClasses = {
      'activity-card-title': true,
      'activity-card-title-mini': this.mini,
      'd2l-body-compact' : true,
      'd2l-skeletize': true,
    };

    const illustration = html`
      <img alt="" src="${ifDefined(this.activity.cardImageUrl)}">
    `;

    const statusIndicator = this.activity.hasTag('active') ?
      html`<d2l-status-indicator class="d2l-skeletize" state="none" text="${this.activity.getTranslatedValue('type')}"></d2l-status-indicator>`
      : html`<d2l-status-indicator class="d2l-skeletize" state="alert" text="${this.localize('view-activity.inactive')}" bold></d2l-status-indicator>`;

    const badge = !this.mini ? statusIndicator : nothing;

    const metaTag = attr => {
      const activityCost = this.activity?.tempCost;
      const { settings } = this.session;

      const formattedCost = activityCost.formatAsDecimal(settings.language);
      const costAttribute = html`<span aria-label="${formattedCost}">${formattedCost}</span>`;
      const notScheduledDate = html`
        <nova-tooltip
          text=${this.activity[attr]}
          tooltip-text="${this.localize('activity-small.notScheduledDescription')}">
        </nova-tooltip>`;
      const normalAttribute = html`<span>${this.activity.getTranslatedValue(attr) || this.activity[attr]}</span>`;

      if (attr === 'formattedCost') return costAttribute;
      if (attr === 'formattedStartDate') {
        if (!this.activity.isScheduled() && this.disabled) {
          return notScheduledDate;
        } else if (this.activity.startDateType === 'date' && this.activity.isScheduled()) {
          return localizeStartDate(ActivitiesHelper.getNextSessionDateAsString(this.activity.startDate));
        }
      }
      return normalAttribute;
    };

    if (!this.activity.hasTag('allowRequest') || this.session._tenant.hasTag('paymentHandledByProvider')) this.displayAttributes = this.displayAttributes.filter(attr => attr !== 'formattedCost');

    const supportingInfo = html`
      <div id="supporting-info">
      ${this.displayAttributes.filter(attr => attr !== 'formattedStartDate' || this.activity.hasTag('allowRequest')).map(attr => html`
        ${metaTag(attr)}
        <span class="bullet">•</span>
      `)}
      </div>
    `;
    return this.wrapWithLink(html`
      <div class="${classMap(containerClasses)}"
        @d2l-tooltip-show="${this._onTooltipShow}"
        @d2l-tooltip-hide="${this._onTooltipHide}">
        <div class="${classMap(linkContainerClasses)}">
          <div class="activity-card-illustration d2l-skeletize">${illustration}</div>
          <div class="${classMap(cardContentClasses)}">
            <div class="activity-card-badge">${badge}</div>
            <div class="${classMap(titleClasses)}">${this.activity.title}</div>
            <div class="activity-card-supporting-info d2l-body-small d2l-skeletize">${supportingInfo}</div>
          </div>
        </div>
        <div class="activity-card-actions"><slot name="actions"></slot></div>
      </div>
    `);
  }

  async updated(_changedProperties) {
    for (const [propName, oldValue] of _changedProperties) {
      if (propName === 'activity' && this.activity && oldValue !== this.activity) {
        this.text = this.activity.getTranslatedValue('type') || this.text;
      }
    }
    if (this.activity && this.activity.id && !this.noNav) {
      this.href = `/activities/${this.activity.id}`;
    }
  }

  _onTooltipHide() {
    this._tooltipShowing = false;
  }

  _onTooltipShow() {
    this._tooltipShowing = true;
  }
}

window.customElements.define('activity-small', ActivitySmall);
