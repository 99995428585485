import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/inputs/input-date.js';
import '@brightspace-ui/core/components/inputs/input-fieldset.js';
import '@brightspace-ui/core/components/tabs/tab-panel.js';
import '@brightspace-ui/core/components/tabs/tabs.js';
import '@brightspace-ui-labs/pagination/pagination.js';

import './custom-terms.js';
import './manage-activity-instructions.js';
import './manage-budget-approvals.js';
import './manage-career-explorer.js';
import './manage-custom-content.js';
import './manage-general.js';
import './manage-provider-finance.js';
import './manage-users.js';
import '../../components/visibility-manager.js';
import '../../../admin/components/audit/audit-table.js';
import '../../../admin/components/login-manager.js';
import '../../components/skill-stream-manager.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading1Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { navigator as nav } from 'lit-element-router';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { LocalizeNova } from '../../../shared/mixins/localize-nova.js';
import Tenant from '../../../../shared/models/tenant/index.js';

/**
 * Shows a list of the courses
 */
export default class EditTenant extends LocalizeNova(RequesterMixin(nav(LitElement))) {

  static get properties() {
    return {
      params: { type: Object },
      _currentRole: { type: String, attribute: false },
      _providers: { type: Array, attribute: false },
      _tenant: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      inputLabelStyles,
      selectStyles,
      css`
        d2l-input-text {
          padding-bottom: 1.5rem;
        }

        .d2l-input-select {
          margin-bottom: 1.5rem;
        }
`,
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
  }

  async firstUpdated() {
    this._currentRole = '';

    this._tenant = this.params?.id ?
      await this.client.fetchTenant(this.params.id) :
      new Tenant({ type: this.params.type });

    this._providers = await this.client.listTenants('provider');
  }

  render() {
    if (!this._tenant) return html`Loading`;

    if (this.params?.type) {
      return html`
        <h1 class="d2l-heading-1">Create new ${this.params.type} tenant</h1>
        <manage-general .tenant=${this._tenant} @update-tenant=${this._updateTenant} create-mode></manage-general>
      `;
    }

    return html`
      <h1 class="d2l-heading-1">${this._tenant.name}</h1>
      <d2l-tabs>
        <d2l-tab-panel text="General" selected>
          <manage-general .tenant=${this._tenant} @update-tenant=${this._updateTenant}></manage-general>
        </d2l-tab-panel>

        ${this._tenant.type === 'employer' ? html`
          <d2l-tab-panel text="Career Explorer">
            <manage-career-explorer .tenant=${this._tenant} @update-tenant=${this._updateTenant}></manage-career-explorer>
          </d2l-tab-panel>
        ` : nothing }

        ${this._tenant.type === 'employer' ? html`
        <d2l-tab-panel text="${this.localize('manage-budget-approvals.title')}">
          <manage-budget-approvals .tenant=${this._tenant} @update-tenant=${this._updateTenant}></manage-budget-approvals>
        </d2l-tab-panel>
        ` : nothing }

        ${this._tenant.type === 'provider' ? html`
          <d2l-tab-panel text="${this.localize('manage-provider-finance.title')}">
            <manage-provider-finance .tenant=${this._tenant} @update-tenant=${this._updateTenant}></manage-provider-finance>
          </d2l-tab-panel>
        ` : nothing}

        ${this._tenant.type !== 'admin' ? html`
        <d2l-tab-panel text="How it works page content">
          <manage-custom-content
            itemDisplay="Block"
            type="homepage"
            tenantId=${this._tenant.id}>
          </manage-custom-content>
        </d2l-tab-panel>
        ` : nothing }

        ${this._tenant.type === 'employer' ? html`
        <d2l-tab-panel text="Employee terms of use">
          <custom-terms .employerName=${this._tenant.name} .tenantId=${this._tenant.id}></custom-terms>
        </d2l-tab-panel>
        ` : nothing }

        ${this._tenant.type !== 'admin' ? html`
        <d2l-tab-panel text="FAQ">
          <manage-custom-content
            itemDisplay="FAQ"
            type="faq"
            tenantId=${this._tenant.id}>
          </manage-custom-content>
        </d2l-tab-panel>
        ` : nothing }

        ${this._tenant.type === 'provider' ? html`
          <d2l-tab-panel text="Activity instructions">
            <manage-activity-instructions .tenant=${this._tenant}></manage-activity-instructions>
          </d2l-tab-panel>
        ` : nothing }

        ${this._tenant.type !== 'admin' ? html`
        <d2l-tab-panel text="Entitlements">
          <manage-users
            type="Entitlement"
            @users-updated=${this._updateEntitlements}
            .users=${this._tenant.entitlements}
            .tenantType=${this._tenant.type}>
          </manage-users>
        </d2l-tab-panel>
        ` : nothing }

        <d2l-tab-panel text="Login">
          <login-manager tenant-id="${this._tenant.id}"></login-manager>
        </d2l-tab-panel>

				${this._tenant.type === 'employer' ? html`
					<d2l-tab-panel text="Visibility">
					${this._providers ? html`
						<visibility-manager
							.providers=${this._providers}
							.contextTenant=${this._tenant}></visibility-manager>
					` : html`Loading`}
					</d2l-tab-panel>
        ` : nothing }

        ${this._tenant.type === 'employer' ? html`
        <d2l-tab-panel text="Enabled Streams">
          <skill-stream-manager
          .contextTenant=${this._tenant}></skill-stream-manager>
        </d2l-tab-panel>` : nothing}

        <d2l-tab-panel text="Audit">
          <audit-table tenant=${this.params.id}></audit-table>
        </d2l-tab-panel>
      </d2l-tabs>
    `;
  }

  async _updateEntitlements(e) {
    const updatedTenant = await this.client.setEntitlements(this._tenant.id, e.detail.users);
    this._tenant.entitlements = { ...updatedTenant.entitlements };
  }

  _updateTenant(e) {
    const { tenant } = e.detail;
    this._tenant = new Tenant(tenant);
  }
}

window.customElements.define('edit-tenant', EditTenant);
