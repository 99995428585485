import '@brightspace-ui/core/components/button/button.js';
import { html, LitElement } from 'lit';

class NovaAsyncButton extends LitElement {
  static get properties() {
    return {
      // Use action property rather than the @click event listener
      action: { type: Function },
      _disabled: { type: Boolean },
      disabled: { type: Boolean },
      primary: { type: Boolean },
    };
  }

  async _handleClick() {
    this._disabled = true;
    try {
      await this.action();
    } finally {
      this._disabled = false;
    }
  }

  render() {
    return html`
      <d2l-button ?primary=${!!this.primary} ?disabled=${this._disabled || this.disabled} @click=${this._handleClick}>
        <slot></slot>
      </d2l-button>
    `;
  }
}

customElements.define('nova-async-button', NovaAsyncButton);
