import { css, html, LitElement } from 'lit';
import { bodySmallStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { repeat } from 'lit/directives/repeat.js';
import { styleMap } from 'lit/directives/style-map.js';

import { getTableHeaders } from '../application-table/templates.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova.js';
import { novaTableStyles } from '../application-table/styles.js';

class ApplicationTableHeader extends LocalizeNova(LitElement) {

  static get properties() {
    return {
      persona: { type: String, reflect: false },
      tenant: { type: Object, reflect: false },
      _headers: { type: Array, attribute: false },
      currentSortingColumn: { type: Object, reflect: false },
      isPaymentHandledByProvider: { type: Boolean, reflect: false },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      novaTableStyles,
      css`
        .table-header[role=row] {
          display: grid;
          grid-column-gap: 1.1rem;
          padding: 0 20px;
          color: var(--d2l-color-celestine);
          font-weight: bold;
        }

        .table-item[role=columnheader] {
          display: flex;
          align-items: center;
          margin: 0;
          color: var(--d2l-color-celestine);
          font-weight: bold;
        }

        .sort-icons-wrapper {
          display: flex;
          flex-direction: column;
          margin-left: 0.1rem;
          cursor: pointer;
        }

        button {
          display: inline-flex;
          align-items: center;
          margin: 0;
          padding: 1px 0 0 0;
          color: var(--d2l-color-celestine);
          font-weight: bold;
          font-size: inherit;
          font-family: inherit;
          text-decoration: none;
          background-color: transparent;
          border: none;
          cursor: pointer;

        }

        d2l-icon {
          color: var(--d2l-color-celestine);
        }

        .sort-neutral {
          display: flex;
          color: inherit;
        }

        @media (max-width: 960px) {
          .table-header[role=row], .table-header[role=presentation] {
            display: none;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this._headers = [];
    this._sortableColumns = [
      'employee',
      'member',
      'jobseeker',
      'activity',
      'submitted',
      // 'nextSession',
      'price',
    ];
    this.currentSortingColumn = {};
    this._removeAriaSortAttribute = this._removeAriaSortAttribute.bind(this);
    this.isPaymentHandledByProvider = false;
  }

  firstUpdated() {
    this.isPaymentHandledByProvider = this.tenant.hasTag('paymentHandledByProvider');
    this._headers = getTableHeaders(this.persona, this.tenant);
  }

  render() {
    return html`
      <div class="table-header" role="row" tabindex="-1" style=${styleMap(this.getHeaderGridColumns())}>
        ${repeat(this._headers, header => this._columnHeader(header))}
      </div>
    `;
  }

  getHeaderGridColumns() {
    return {
      'grid-template-columns': this.isPaymentHandledByProvider
        ? '0.4fr 0.8fr 2.5fr 0.9fr 1fr 1fr'
        : '0.4fr 1.5fr 1.5fr 0.9fr 1fr 1fr 1fr',
    };
  }

  _columnHeader(headerName) {
    const headerNode = this._sortableColumns.includes(headerName)
      ? this._renderSortButton(headerName)
      : this.localize(`application-table.table.${headerName}`);

    const tabIndex = this._sortableColumns.includes(headerName)
      ? '-1'
      : '0';

    return html`
      <div
        class="table-item d2l-body-small"
        role="columnheader"
        tabindex="${tabIndex}">
      ${headerNode}
    </div>`;
  }

  _dispatchSortEvent() {
    const requestSortEvent = new CustomEvent('request-sort-event', {
      detail: this.currentSortingColumn,
      bubbles: true,
      composed: true });
    this.dispatchEvent(requestSortEvent);
  }

  _handleSortButtonClick(e) {
    if (this.currentSortingColumn.colName) {
      this._removeAriaSortAttribute();
    }
    const headerName = e.currentTarget.id.split('-')[0];
    if (headerName !== this.currentSortingColumn.colName) {
      this.currentSortingColumn.order = 'asc';
    }
    else {
      this.currentSortingColumn.order = this.currentSortingColumn.order === 'desc' ? 'asc' : 'desc';
    }
    this.currentSortingColumn.colName = headerName;
    const ariaSortText = this.currentSortingColumn.order === 'asc' ? 'ascending' : 'descending';
    e.currentTarget.parentElement.setAttribute('aria-sort', ariaSortText);
    this._dispatchSortEvent();
    this.requestUpdate();
  }

  _removeAriaSortAttribute() {
    const id = `#${this.currentSortingColumn.colName}-sort`;
    this.shadowRoot.querySelector(id).parentElement.removeAttribute('aria-sort');
  }

  _renderSortButton(header) {
    const id = `${header}-sort`;
    return html`
      <button
        id=${id}
        @click=${this._handleSortButtonClick}
        title=${this.localize('application-table.table.sortingButtonTitle', { header: this.localize(`application-table.table.${header}`) })}
        tabindex="0"
        aria-label=${this.localize(`application-table.table.${header}`)}>
        ${this.localize(`application-table.table.${header}`)}
        <div class="sort-icons-wrapper" tabindex="-1">
          ${this.currentSortingColumn.colName !== header ? this._renderUpDownIcon() : this._renderSortIcon()}
        </div>
      </button>
    `;
  }

  _renderSortIcon() {
    const icon = this.currentSortingColumn.order === 'desc' ? 'tier1:arrow-toggle-down' : 'tier1:arrow-toggle-up';
    return html`
      <d2l-icon icon=${icon}></d2l-icon>
    `;
  }

  _renderUpDownIcon() {
    return html`
      <div class="sort-neutral">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
          <g clip-path="url(#a)">
            <path d="M12.137 10.193a.67.67 0 0 0-.472-.193h-5.33a.667.667 0 0 0-.467 1.14l2.667 2.667a.675.675 0 0 0 .947 0l2.666-2.667a.674.674 0 0 0-.008-.947h-.003Z" fill="var(--d2l-color-celestine)"/>
          </g>
          <g clip-path="url(#b)">
            <path d="M12.137 7.807a.67.67 0 0 1-.472.193h-5.33a.667.667 0 0 1-.467-1.14l2.667-2.667a.675.675 0 0 1 .947 0l2.666 2.667a.674.674 0 0 1-.008.947h-.003Z" fill="var(--d2l-color-celestine)"/>
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" transform="translate(5.667 10)" d="M0 0h6.667v4H0z"/>
            </clipPath>
            <clipPath id="b">
              <path fill="#fff" transform="matrix(1 0 0 -1 5.667 8)" d="M0 0h6.667v4H0z"/>
            </clipPath>
          </defs>
        </svg>
      </div>
    `;
  }
}

window.customElements.define('application-table-header', ApplicationTableHeader);
