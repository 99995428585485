import { bodySmallStyles, heading4Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { offscreenStyles } from '@brightspace-ui/core/components/offscreen/offscreen.js';
import { styleMap } from 'lit/directives/style-map.js';

const PROFILE_IMAGE_COLORS = [
  '#8b271f',
  '#c74f05',
  '#527f1f',
  '#346633',
  '#165f5b',
  '#0c7683',
  '#3155bf',
  '#6f6bb8',
  '#9860af',
  '#804167',
  '#d13b7f',
  '#3b4148',
];

class ProfileImage extends LitElement {

  static get properties() {
    return {
    /**
     * The attribute to display in the metatag
     * @type {'department'|'title'|'callbackUrl'|'none'}
     * @default 'department'
     */
      metaAttribute: { type: String, reflect: true, attribute: 'meta-attribute' },
      /**
       * Use format template for mobile dialog setting
       * @type { Boolean }
       * @default false
       */
      mobileFormat: { type: Boolean, attribute: 'mobile-format' },

      /**
       * Allow full width profile image
       * @type { Boolean }
       * @default false
       */
      fullWidth: { type: Boolean, attribute: 'full-width' },

      /**
       * Render the badge in illustration aspect ratio
       * @type { Boolean }
       * @default false
       */
      illustration: { type: Boolean, attribute: 'illustration' },

      /**
       * Hide the profile text
       * @type { Boolean }
       * @default false
       */
      hideProfileText: { type: Boolean, attribute: 'hide-profile-text' },

      user: { type: Object },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      heading4Styles,
      offscreenStyles,
      css`
        .profile-wrapper {
          display: flex;
          gap: 12px;
          align-items: center;
        }

        .initials-badge-illustration,
        .initials-badge {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          color: var(--d2l-color-regolith);
          font-size: 0.95rem;
          text-align: center;
          border-radius: 6px;
        }

        .initials-badge {
          width: 42px;
          min-width: 42px;
          height: 42px;
        }

        .initials-badge-illustration {
          width: 90px;
          min-width: 90px;
          height: 50px;
        }

        .image-badge {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
          border-radius: 6px;
        }

        .profile-text-wrapper,
        .profile-text-wrapper-mobile {
          display: flex;
          flex-flow: column wrap;
          align-items: flex-start;
          justify-content: center;
          width: 100%;
          max-width: 250px;
          margin: 0;
          overflow: hidden;
          white-space: nowrap;
        }

        .offscreen-display-name {
          display: none;
        }

        .profile-name-text,
        .profile-meta-text {
          flex: 1 1 auto;
          width: 100%;
          min-width: 40px;
          margin: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .profile-name-text-department {
          font-weight: bold;
        }

        .meta-font-desktop {
          font-size: 0.6rem;
          line-height: 0.9rem;
        }

        .no-meta-attribute,
        .no-profile-name {
          display: none;
        }

        .full-width {
          max-width: 100% !important;
        }

        @media (max-width: 1024px) {
          :host(:not([full-width])) .profile-text-wrapper {
            display: none;
          }

          .offscreen-display-name {
            display: initial;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.metaAttribute = 'department';
    this.mobileFormat = false;
  }

  render() {
    if (!this.user) return nothing;

    return html`
      <span class="profile-wrapper">
        ${this._badgeTemplate}
        ${this._profileTextTemplate}
      </span>
    `;
  }
  get _badgeClasses() {
    return {
      'initials-badge': !this.illustration,
      'initials-badge-illustration': this.illustration,
    };
  }

  get _badgeTemplate() {
    const badge = this.user.imageUrl ?
      html`<img class="image-badge" src="${this.user.imageUrl}"/>` :
      html`${this._userInitials}`;

    return html`<div class="${classMap(this._badgeClasses)}" style=${styleMap(this._userBadgeColor)} aria-hidden="true">${badge}</div>`;
  }

  get _metaTextClasses() {
    return {
      'profile-meta-text': true,
      'd2l-body-small': this.mobileFormat,
      'meta-font-desktop': !this.mobileFormat,
      'no-meta-attribute': this.metaAttribute === 'none' || this.user[this.metaAttribute]?.length === 0,
    };
  }

  get _nameTextClasses() {
    return {
      'profile-name-text': true,
      'profile-name-text-department': this.metaAttribute === 'department' && this.user[this.metaAttribute]?.length > 0,
      'no-profile-name': this.hideProfileText,
    };
  }

  get _profileTextTemplate() {
    return html`<div class=${classMap(this._profileWrapperClasses)}>
          <div class=${classMap(this._nameTextClasses)}>${this.user.getDisplayName()}</div>
          <div class=${classMap(this._metaTextClasses)}>${this.user[this.metaAttribute]}</div>
        </div>
        ${!this.mobileFormat ? html`
          <span class="d2l-offscreen offscreen-display-name">${this.user.getDisplayName()}</span>
        ` : nothing}`;
  }

  get _profileWrapperClasses() {
    return {
      'full-width': this.fullWidth,
      'profile-text-wrapper': !this.mobileFormat,
      'd2l-body-small': !this.mobileFormat,
      'profile-text-wrapper-mobile': this.mobileFormat,
      'd2l-heading-4': this.mobileFormat,
    };
  }

  get _userBadgeColor() {
    if (this.user.imageUrl) return {};
    const hash = this._hashStr(this.user.getDisplayName());
    const index = hash % PROFILE_IMAGE_COLORS.length;
    return { backgroundColor: `${PROFILE_IMAGE_COLORS[index]}` };
  }

  get _userInitials() {
    const displayName = this.user.getDisplayName();
    return displayName.includes('@')
      ? this._extractInitials(displayName.split('@').shift().split(/[-._]/))
      : this._extractInitials(displayName.split(' '));
  }

  _extractInitials(nameArr) {
    const first = nameArr.shift()?.[0] || '';
    const last = nameArr.pop()?.[0] || '';
    return [first, last].join('').toUpperCase();
  }

  _hashStr(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash += str.charCodeAt(i);
    }
    return hash;
  }
}

window.customElements.define('profile-image', ProfileImage);
