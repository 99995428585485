import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/collapsible-panel/collapsible-panel.js';
import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/icons/icon-custom.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/link/link.js';
import '@brightspace-ui/core/components/typography/typography.js';

import { css, html, LitElement, nothing } from 'lit';
import { heading1Styles, labelStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { navigator as nav } from 'lit-element-router';
import { radioStyles } from '@brightspace-ui/core/components/inputs/input-radio-styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';
import { validate } from 'email-validator';

import '../../../../../app/app-link.js';
import { Application } from '../../../../../shared/models/application/index.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova.js';
import Tenant from '../../../../../shared/models/tenant/index.js';

export default class ViewAccountConnection extends LocalizeNova(SkeletonMixin(RequesterMixin(nav(LitElement)))) {

  static get properties() {
    return {
      params: { type: Object },
      _application: { type: Object, attribute: false },
      _provider: { type: Object, attribute: false },
      _isEmailInvalid: { type: Boolean, attribute: false, reflect: false },
      _useCorporateEmail: { type: Boolean, reflect: true },
      _useOtherEmail: { type: Boolean, reflect: true },
      _disableBypassRedirect: { type: Boolean, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      heading1Styles,
      labelStyles,
      radioStyles,
      css`
        :host {
          display: block;
          min-height: 100vh;
          padding: 10px;
        }

        .create-workflow-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin: 0 auto;
          padding: 2.1rem 0 1.05rem 0;
        }

        .create-button-container {
          display: flex;
          gap: 0.6rem;
          padding-bottom: 0.6rem;
        }

        .create-support-container {
          display: flex;
          flex-basis: 100%;
          align-items: center;
          justify-content: center;
        }

        d2l-collapsible-panel {
          margin-top: 30px;
        }

        d2l-dialog img {
          height: 55px;
        }

        d2l-dialog img[src=""] {
          display: none;
        }

        .support-icon {
          margin: 0 0.6rem;
        }

        .support-link {
          margin: 0 0.3rem;
        }

        #email-text-input {
          display: none;
        }

        :host([_useOtherEmail]) #email-text-input {
          display: block;
          width: min(400px, 100%);
          padding-top: 0.9rem;
        }

        #goto-three {
          padding-top: 0.9rem;
        }

        @media (max-width: 615px) {
          .create-button-container {
            flex-direction: column;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this._application = new Application();
    this._provider = new Tenant();

    this._isEmailInvalid = true;
    this._useCorporateEmail = false;
    this._useOtherEmail = false;
    this._disableBypassRedirect = true;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  render() {
    if (!this._application?.id) return nothing;

    return html`
      <d2l-link .skeletize=${this.skeletize} href=${this._appLink} small>
        ${this.localize('connect-account.backlink')}
      </d2l-link>
      <section>
        <h1 class="d2l-heading-1">${this.localize('connect-account.title')}</h1>
        <p class="d2l-body-standard">
          ${this.localize('connect-account.introduction', { providerName: this._provider.name, registrationTime: this._provider?.registrationTime || '4' })}
        </p>
      </section>
      <div class="caketray-container">
        ${this._createAccountTemplate}
        ${this._connectAccountTemplate}
        ${this._paymentTemplate}
      </div>
    `;
  }

  async updated(_changedProperties) {
    let paramChanged = false;
    for (const [propName] of _changedProperties) {
      if (propName === 'params') {
        paramChanged = true;
      }
    }
    if (paramChanged) await this._loadData();
  }

  get _appLink() {
    return `requests/${this._application?.uuid || ''}`; // fallback set to requests table
  }

  get _connectAccountTemplate() {
    return html`
      <d2l-collapsible-panel
        id="step-two-caketray"
        panel-title=${this.localize('connect-account.stepTwo.title', { providerName: this._provider.name })}>
        <d2l-collapsible-panel-summary-item
          slot="summary"
          text=${this.localize('connect-account.stepTwo.summary', { providerName: this._provider.name })}>
        </d2l-collapsible-panel-summary-item>
        <p>${this.localize('connect-account.stepTwo.question', { providerName: this._provider.name })}</p>
        <label class="d2l-input-radio-label">
          <input id="corporate-email" type="radio" name="user-email" @change="${this._handleRadioChange}">
          ${this.session.user.email}
        </label>
        <label class="d2l-input-radio-label">
          <input id="other-email" type="radio" name="user-email" @change="${this._handleRadioChange}">
          ${this.localize('connect-account.stepTwo.other')}
        </label>
        <d2l-input-text
          id="email-text-input"
          ?required=${this._useOtherEmail}
          @input="${this._handleEmailInput}"
          type='email'
          label=${this.localize('connect-account.stepTwo.textInput.label')}
          placeholder="email@company.com">
        </d2l-input-text>
        <d2l-button
          id="goto-three"
          @click=${this._handleClickGoNext}
          ?disabled=${this._isAllowedToSubmitEmail}
          disabledTooltip=${this.localize('connect-account.stepTwo.disableTooltip')}>
          ${this.localize('connect-account.stepTwo.goNext')}
        </d2l-button>
      </d2l-collapsible-panel>
      ${this._redirectDialogTemplate}
    `;
  }

  get _createAccountTemplate() {
    return html`
      <d2l-collapsible-panel
        id="step-one-caketray"
        panel-title=${this.localize('connect-account.stepOne.title', { providerName: this._provider.name })}>
        <d2l-collapsible-panel-summary-item
          slot="summary"
          text=${this.localize('connect-account.stepOne.summary', { providerName: this._provider.name })}>
        </d2l-collapsible-panel-summary-item>
        <p>${this.localize('connect-account.stepOne.instructions.header')}</p>
        <ul>
          <li>${this.localize('connect-account.stepOne.instructions.one', { userEmail: this.session.user.getEmail() })}</li>
          ${ this._application?.activity?.isAdmissionBased ? html`
          <li>${this.localize('connect-account.stepOne.instructions.admissionsBased')}</li>
          ` : html`
          <li>${this.localize(`connect-account.stepOne.instructions.two.${this.session.tenant.learnerTerminology}`)}</li>`}
          <li>${this.localize('connect-account.stepOne.instructions.three')}</li>
        </ul>
        <div class="create-workflow-container">
          <div class="create-button-container">
            <d2l-button id="create-account-button" @click=${this._handleClickOpenDialog} primary aria-haspopup="true">
              ${this.localize('connect-account.stepOne.button.create')}
            </d2l-button>
            <d2l-button id="goto-two" @click=${this._handleClickGoNext}>
              ${this.localize('connect-account.stepOne.button.skip')}
            </d2l-button>
          </div>
          <div class="create-support-container d2l-label-text">
          <d2l-icon-custom class="support-icon" size="tier3">
            <svg xmlns="http://www.w3.org/2000/svg" width="29.926" height="17.202" viewBox="0 0 29.926 17.202">
              <path id="Path_291" data-name="Path 291" d="M.949-61.911,5.869-67.2a1.709,1.709,0,0,1,1.252-.545h9.86a1.678,1.678,0,0,1,1.678,1.678,1.678,1.678,0,0,1-.011.191l-.04.353c-.1.863.244,1.5-.7,1.515H13.458c-.518-.019-1.3-.119-1.3.348,0,.49.713.287,1.3.324.031,0,1.319.006,1.351.006h4.013a.855.855,0,0,0,.675-.33l5.4-5.955a1.709,1.709,0,0,1,2.189-.44l.27.152a1.55,1.55,0,0,1,.589,2.111,1.551,1.551,0,0,1-.12.182l-7.241,9.446a1.709,1.709,0,0,1-1.357.669H9.3a1.709,1.709,0,0,0-1.3.6l-.833.973a1.709,1.709,0,0,1-2.41.188q-.054-.046-.1-.1L.985-59.545A1.709,1.709,0,0,1,.949-61.911Z" transform="translate(0.654 71.368)" fill="none" stroke="#565A5C" stroke-width="2" fill-rule="evenodd"/>
            </svg>
          </d2l-icon-custom>
            <p>
              ${this.localize('connect-account.stepOne.support.text')}
            </p>
            <app-link d2l-link class="support-link" href="/contact-support">
              ${this.localize('connect-account.stepOne.support.link')}
            </app-link>
          </div>
        </div>
      </d2l-collapsible-panel>
  `;
  }

  get _isFullyCovered() {
    return this._application.costToEmployee().cost < 0.5;
  }

  get _isAllowedToSubmitEmail() {
    return (!this._useCorporateEmail && !this._useOtherEmail)
      || (this._useOtherEmail && this._isEmailInvalid);
  }

  get _isApplicationUser() {
    return this.session.userId === this._application?._userId;
  }

  get _paymentTemplate() {
    const title = this._isFullyCovered ? this.localize('connect-account.stepThree.completion.title', { providerName: this._application.institution }) : this.localize('connect-account.stepThree.payment.title');
    const summary = this._isFullyCovered ? this.localize(`connect-account.stepThree.completion.summary.${this.session.tenant.learnerTerminology}`, { activityType: this._application.activity.type }) : this.localize(`connect-account.stepThree.payment.summary.${this.session.tenant.learnerTerminology}`);
    const instructions = this._isFullyCovered ?
      this.localize(`connect-account.stepThree.completion.instructions.${this.session.tenant.learnerTerminology}`, { activityType: this._application.activity.type, providerName: this._application.institution }) :
      this.localize('connect-account.stepThree.payment.instructions');
    const buttonLabel = this._isFullyCovered ? this.localize('connect-account.stepThree.completion.done') : this.localize('connect-account.stepThree.payment.next');

    return html`
    <d2l-collapsible-panel
      id="step-three-caketray"
      panel-title=${title}>
      <d2l-collapsible-panel-summary-item
        slot="summary"
        text=${summary}>
      </d2l-collapsible-panel-summary-item>
      <p>${instructions}</p>
      <d2l-button ?disabled=${this._isAllowedToSubmitEmail} @click=${this._handleClickPayment}>
        ${buttonLabel}
      </d2l-button>
    </d2l-collapsible-panel>`;
  }

  get _redirectDialogTemplate() {
    return html`
      <d2l-dialog
        id="redirect-dialog"
        title-text=${this.localize('connect-account.dialog.title')}
        @d2l-dialog-close=${this._dialogClose}>
        <img alt="${this._provider.name}" src="${this._provider.imageUrl || ''}">
        <p>${this.localize('connect-account.dialog.description')}</p>
        <d2l-button slot="footer" primary @click=${this._redirectToProvider}>
          ${this.localize('connect-account.dialog.title')}
        </d2l-button>
        <d2l-button slot="footer" ?disabled=${this._disableBypassRedirect} data-dialog-action="proceed">
          ${this.localize('connect-account.dialog.button.proceed')}
        </d2l-button>
      </d2l-dialog>
    `;
  }

  _dialogClose(e) {
    if (e.detail.action === 'proceed') {
      this._handleAmplitudeEvent('accountConnectionStepOne', { providerAccountState: 'created' });
      this._closeCaketray(1);
      this._openCaketray(2);
    }
  }

  _handleAmplitudeEvent(eventType, additionalAttr = {}) {
    this.client.logEvent({
      eventType: eventType,
      providerId: this._application.activity.provider,
      providerName: this._provider.name,
      applicationUuid: this._application.uuid,
      activityTitle: this._application.activity.title,
      ...additionalAttr,
    });
  }

  /**
   * The caketray expanded state wasn't reflecting the change when there wasn't an await before toggling. This is a
   * little hack to force a 0 ms wait to make the expanded state work properly.
   */
  async _timeout() {
    return new Promise(resolve => setTimeout(resolve, 0));
  }

  async _handleClickGoNext(e) {
    if (e.target.id === 'goto-two') {
      // the user already has an account with the provider
      this._handleAmplitudeEvent('accountConnectionStepOne', { providerAccountState: 'existing' });
      this._closeCaketray(1);
      this._openCaketray(2);
    } else {
      // check if the user's provider account email is not different from their Wave email, stringify the boolean
      const providerAccountEmailUsed = (!this._useOtherEmail).toString();
      this._handleAmplitudeEvent('accountConnectionStepTwo', { providerAccountEmailUsed });
      this._closeCaketray(2);
      this._openCaketray(3);
    }
  }

  _handleClickOpenDialog() {
    const redirectDialog = this.shadowRoot.getElementById('redirect-dialog');
    redirectDialog.opened = true;
  }

  async _handleClickPayment() {
    this._handleAmplitudeEvent('accountConnectionStepThree');
    const customEmail = this.shadowRoot.getElementById('email-text-input').value;
    const email = this._useCorporateEmail ? this._application.user.getEmail() : customEmail;
    await this.client.setAccountConnection(this._application.user.guid, this._provider.id, email);

    const { amount } = await this.client.convertCostToEmployee(this._application.uuid);
    if (amount >= 0.50) {
      const response = await this.client.createPaymentFlow({ uuid: this._application.uuid });
      location.replace(response.sessionUrl);
    } else {
      await this.client.setPaymentDate(this._application.uuid);
      this.navigate(`/requests/${this._application.uuid}/confirmation`);
    }
  }

  _handleEmailInput() {
    this._isEmailInvalid = !validate(this.shadowRoot.getElementById('email-text-input').value);
  }

  _handleRadioChange(e) {
    this._useCorporateEmail = e.target.id === 'corporate-email';
    this._useOtherEmail = e.target.id === 'other-email';
  }

  async _loadData() {
    this.skeleton = true;
    const { uuid } = this.params;
    try {
      this._application = await this.client.getApplication(uuid);
      this._provider = await this.client.fetchTenant(this._application.activity.provider);
      this._openCaketray(1);
      this._handleAmplitudeEvent('accountConnectionStarted');
    } catch (e) {
      if (e.status === 403 || e.status === 404) {
        this.navigate('/requests');
      } else {
        throw e;
      }
    }
    this.skeleton = false;
  }

  async _openCaketray(target) {
    await this._timeout(0);
    const targetMap = { 1: 'one', 2: 'two', 3: 'three' };
    const selector = ['step', targetMap[target], 'caketray'].join('-');
    const element = this.shadowRoot.getElementById(selector);
    element.setAttribute('expanded', '');
  }

  async _closeCaketray(target) {
    await this._timeout(0);
    const targetMap = { 1: 'one', 2: 'two', 3: 'three' };
    const selector = ['step', targetMap[target], 'caketray'].join('-');
    const element = this.shadowRoot.getElementById(selector);
    element.removeAttribute('expanded');
  }

  _redirectToProvider() {
    this._disableBypassRedirect = false;
    window.open(this._provider.accountCreationUrl, '_blank', 'noopener');
  }
}

window.customElements.define('view-account-connection', ViewAccountConnection);
