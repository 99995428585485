import { css, html, LitElement, nothing } from 'lit';

import { FormElementMixin } from '@brightspace-ui/core/components/form/form-element-mixin.js';
import { inputStyles } from '@brightspace-ui/core/components/inputs/input-styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { repeat } from 'lit/directives/repeat.js';

import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { labelStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { offscreenStyles } from '@brightspace-ui/core/components/offscreen/offscreen.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { SUPPORTED_COUNTRIES, SUPPORTED_TRANSCRIPTS } from '../../../../shared/constants.js';
import { LocalizeNova } from '../../mixins/localize-nova.js';
import { SUPPORTED_STATES } from '../../../../shared/location.js';

import '../tooltip/nova-tooltip.js';
import './nova-postal-code-input.js';
import './nova-phone-number-input.js';
import './nova-checkbox-input.js';
import { LocalizedValue } from '../../../../shared/models/localized-value.js';

const INPUT_TEXT_TYPES = ['email', 'text'];
const INPUT_SELECT_TYPES = ['state', 'province', 'country', 'transcript'];
const POSTAL_CODE_TYPES = ['postalCode'];
const PHONE_NUMBER_TYPES = ['phone'];
const CHECKBOX_TYPES = ['checkbox'];
const HEADER_TYPES = ['header'];

const SELECT_OPTIONS = {
  state: SUPPORTED_STATES.US,
  province: SUPPORTED_STATES.CA,
  country: SUPPORTED_COUNTRIES,
  transcript: SUPPORTED_TRANSCRIPTS,
};
class CustomAttributeInput extends LocalizeNova(SkeletonMixin(FormElementMixin(LitElement))) {

  static get properties() {
    return {
      customAttribute: { type: Object },
      disabled: { type: Boolean },
      user: { type: Object },
      hidden: { type: Boolean, reflect: true },
      _error: { state: true },
    };
  }

  static get styles() {
    return [
      super.styles,
      inputLabelStyles,
      inputStyles,
      labelStyles,
      selectStyles,
      offscreenStyles,
      css`
        :host {
          display: block;
        }
        :host([hidden]) {
          display: none;
        }

        .select-input {
          width: 100%;
        }
        .d2l-input-label-required::after {
          left: 0;
        }
`,
    ];
  }

  firstUpdated() {
    // If the attribute is a hidden attribute - dispatch the default value. This is to ensure that the user object is updated with the default value.
    if (this.customAttribute.inputType === 'hidden') {
      this._dispatchChangeEvent(this._value);
      this.hidden = true;
    }
  }

  _onChange(e) {
    const value = e.detail?.value || e.target.value;
    this.user[this.customAttribute.name] = value;
    this._dispatchChangeEvent(value);
    this._inputElement.removeAttribute('aria-invalid');
    this._error = undefined;
  }

  _dispatchChangeEvent(value) {
    this.dispatchEvent(new CustomEvent('change', {
      bubbles: true,
      composed: true,
      detail: {
        attributeName: this.customAttribute.name,
        attributeValue: value,
      },
    }));
  }

  get _userLang() {
    return this.user?.settings?.language;
  }

  get _label() {
    if (this.customAttribute.displayName.langTermKey) return this.localize(this.customAttribute.displayName.langTermKey);
    return this.customAttribute.displayName[this._userLang] || this.customAttribute.displayName.en;
  }

  get _selectOptions() {
    return SELECT_OPTIONS[this.customAttribute.inputType];
  }

  get _tooltipText() {
    return this.customAttribute.tooltipText.getTerm(this._userLang);
  }

  get _value() {
    const value = this.customAttribute?.value;

    if (this.isSelectType && !this._selectOptions[value]) return '';
    return value === undefined ? '' : value;
  }

  get _inputElement() {
    return this.shadowRoot.querySelector(`#${this.customAttribute.name}`);
  }

  get isDisabled() {
    return this.disabled || this.customAttribute.disabled;
  }

  get _invalid() {
    return this._error !== undefined;
  }

  async validate() {
    if (this.isSelectType && ! this._inputElement.validity.valid) {
      this._inputElement.setAttribute('aria-invalid', 'true');
      this._error = this.localize('components.form-element.valueMissing', { label: this._label });
      return [this._error];
    }

    if (this.isHeaderType) return true;

    return this.customAttribute.inputType === 'hidden' || this.isSelectType || (await this._inputElement.validate());
  }

  /**
   * The select element needs to be treated specially. D2L Core components don't have a select version of they component,
   * so we can't mark it as invalid the same way we do for the input-text component(by calling validate). We have to manage
   * the invalid state ourselves.
   *
   * @private
   */
  get _errorTooltipTemplate() {
    return this._invalid
      ? html`
        <d2l-tooltip
          align="start"
          for="${this.customAttribute.name}"
          state="error">
          ${this._error}
        </d2l-tooltip>`
      : nothing;
  }

  get _labelTooltipTemplate() {
    if (this.customAttribute.tooltipText.type === 'label') {
      return html`
          <nova-tooltip
                  text="${this._label}"
                  tooltip-text=${this._tooltipText}>
          </nova-tooltip>`;
    } else {
      return this._label;
    }
  }

  get _tooltipTemplate() {
    if (this.customAttribute.tooltipText.type !== 'label' && this._tooltipText) {
      return html`
        <d2l-tooltip for="${this.customAttribute.name}" align="start">
          ${this._tooltipText}
        </d2l-tooltip>
      `;
    }
    return nothing;
  }

  get _inputTextTemplate() {
    return html`
      <div class="text-input">
        ${this._labelTemplate}
        <d2l-input-text
          id="${this.customAttribute.name}"
          labelled-by="${this.customAttribute.name}_label"
          @change="${this._onChange}"
          label="${this._label}"
          ?required="${this.customAttribute.required}"
          ?disabled="${this.isDisabled}"
          type="${this.customAttribute.inputType}"
          value="${this._value}"
        ></d2l-input-text>
      </div>`;
  }

  get _postalCodeTemplate() {
    return html`
      <div class="text-input">
        ${this._labelTemplate}
        <nova-postal-code-input
          id="${this.customAttribute.name}"
          labelled-by="${this.customAttribute.name}_label"
          @change="${this._onChange}"
          label="${this._label}"
          ?required="${this.customAttribute.required}"
          ?disabled="${this.isDisabled}"
          radius="${this.customAttribute.customOptions?.radius}"
          originPostalCode="${this.customAttribute.customOptions?.originPostalCode}"
          country="${this.customAttribute.customOptions?.country}"
          ?shouldValidateDistance="${this.customAttribute.customOptions?.shouldValidateDistance}"
          value="${this._value}"
        ></nova-postal-code-input>
      </div>`;
  }

  get _phoneNumberTemplate() {
    return html`
      <div class="text-input">
        ${this._labelTemplate}
        <nova-phone-number-input
          id="${this.customAttribute.name}"
          labelled-by="${this.customAttribute.name}_label"
          @change="${this._onChange}"
          label="${this._label}"
          ?required="${this.customAttribute.required}"
          ?disabled="${this.isDisabled}"
          country="${this.customAttribute.customOptions?.country}"
          value="${this._value}"
        ></nova-phone-number-input>
      </div>`;
  }

  get _checkboxTemplate() {
    const customError = this.customAttribute.customOptions?.customError ? new LocalizedValue(this.customAttribute.customOptions?.customError) : undefined;
    return html`
      <nova-checkbox-input
        id="${this.customAttribute.name}"
        @change="${this._onChange}"
        ?required="${this.customAttribute.required}"
        ?disabled="${this.isDisabled}"
        ?value="${this._value}"
        custom-error="${ifDefined(customError?.getTerm(this._userLang))}"
      >
        ${this._labelTooltipTemplate}
      </nova-checkbox-input>`;
  }

  get _headerTemplate() {
    return html`
      <label for="${this.customAttribute.name}" id="${this.customAttribute.name}_label">
        ${new LocalizedValue(this.customAttribute.displayName).getTerm(this._userLang)}  
      </label>`;
  }

  get _labelTemplate() {
    const labelInputs = {
      'd2l-input-label': true,
      'd2l-input-label-required': this.customAttribute.required,
    };
    return html`
      <label for="${this.customAttribute.name}" id="${this.customAttribute.name}_label" class="${classMap(labelInputs)}">
        ${this._labelTooltipTemplate}
      </label>`;
  }

  get _selectInputTemplate() {
    const options = SELECT_OPTIONS[this.customAttribute.inputType];
    // This is a bit of a hack because our translation structure for state/provinces is different than our structure for countries.
    const getOptionTranslation = key => {
      return this.localize(options[key]) || options[key][this._userLang] || options[key].en;
    };
    return html`
      <div class="select-input">
        ${this._labelTemplate}
        <select
          id="${this.customAttribute.name}"
          class="d2l-input-select select-input"
          aria-label="${this._label}"
          ?disabled=${this.isDisabled}
          ?required=${this.customAttribute.required}
          @change=${this._onChange} >
          <option value="" .selected=${(this._value === '')} disabled hidden> </option>
          ${repeat(Object.keys(options), key => key, key => html`
            <option
              value=${key}
              .selected=${(this._value === key)}>
              ${getOptionTranslation(key)}
            </option>
          `)}
        </select>
          ${this._errorTooltipTemplate}
      </div>
    `;
  }

  get isTextType() {
    return INPUT_TEXT_TYPES.includes(this.customAttribute.inputType);
  }

  get isSelectType() {
    return INPUT_SELECT_TYPES.includes(this.customAttribute.inputType);
  }
  get isPostalCodeType() {
    return POSTAL_CODE_TYPES.includes(this.customAttribute.inputType);
  }
  get isPhoneNumberType() {
    return PHONE_NUMBER_TYPES.includes(this.customAttribute.inputType);
  }
  get isCheckboxType() {
    return CHECKBOX_TYPES.includes(this.customAttribute.inputType);
  }

  get isHeaderType() {
    return HEADER_TYPES.includes(this.customAttribute.inputType);
  }

  render() {
    if (this.isTextType) {
      return [this._inputTextTemplate, this._tooltipTemplate];
    } else if (this.isSelectType) {
      return [this._selectInputTemplate, this._tooltipTemplate];
    } else if (this.isPostalCodeType) {
      return [this._postalCodeTemplate, this._tooltipTemplate];
    } else if (this.isPhoneNumberType) {
      return [this._phoneNumberTemplate, this._tooltipTemplate];
    } else if (this.isCheckboxType) {
      return [this._checkboxTemplate, this._tooltipTemplate];
    } else if (this.isHeaderType) {
      return [this._headerTemplate, this._tooltipTemplate];
    }
  }
}

window.customElements.define('custom-attribute-input', CustomAttributeInput);
