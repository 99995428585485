import '@brightspace-ui/core/components/card/card.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/icons/icon-custom.js';

import { bodyCompactStyles, bodySmallStyles, heading2Styles, heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { css, html, LitElement, nothing } from 'lit';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { LocalizeNova } from '../../mixins/localize-nova.js';
import { ReportMixin } from '../../mixins/report-mixin/report-mixin.js';

class ReportWidget extends LocalizeNova(ReportMixin(SkeletonMixin(LitElement))) {

  static get properties() {
    return {
      customIcon: { type: String, reflect: true },
      icon: { type: String, reflect: true },
    };
  }

  static get styles() {
    return [
      super.styles,
      bodyCompactStyles,
      bodySmallStyles,
      heading2Styles,
      heading3Styles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
          max-height: 600px;
          border-color: var(--d2l-color-mica);
        }

        .widget {
          width: 100%;
          height: 100%;
          border-color: var(--d2l-color-mica);
        }

        .widget:focus {
          outline: none;
          box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--d2l-color-celestine);
        }

        .widget-header {
          display: grid;
          grid-row-gap: 8px;
          grid-template-rows: 1fr 1fr;
          grid-template-columns: 1fr;
          align-content: end;
          height: 100px;
          padding: 1.5rem 1.2rem;
          color: white;
          background-color: var(--d2l-color-celestine);
        }

        .header-top {
          display: grid;
          grid-column: 1 / span 2;
          grid-column-gap: 12px;
          grid-template-columns: auto 1fr;
          align-self: start;
        }

        .header-title {
          margin: 0;
          font-weight: normal;
        }

        d2l-icon, d2l-icon-custom {
          align-self: center;
          color: inherit;
          vertical-align: top;
        }

        .header-bottom {
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: end;
        }

        .header-bottom > .left {
          align-self: end;
        }

        .header-bottom > .right {
          align-self: end;
          text-align: right;
        }

        .pct-change {
          margin-bottom: 6px;
          color: white;
          line-height: initial;
        }

        .actual-value {
          margin: 0;
          white-space: nowrap;
        }

        .widget-content {
          display: grid;
          grid-template-columns: 1.2fr 0.8fr;
          gap: 12px 8px;
          align-items: end;
          padding: 0 0.4rem 0.3rem;
        }

        :host([type="adoption"]) .widget-content {
          grid-template-columns: 2fr 1fr;
        }

        .widget-content > .left {
          margin: 0;
          line-height: initial;
          text-align: left;
        }

        .widget-content > .right {
          margin: 0;
          font-weight: bold;
          line-height: initial;
          text-align: right;
        }

        .sub-header-title {
          font-size: 15px;
        }

        .content-value {
          white-space: nowrap;
        }

        @media (max-width: 767px) {

          .widget-header {
            gap: 4px 8px;
            height: 80px;
            padding: 1.5rem 0.8rem 1.4rem;
          }

          .pct-change {
            margin-bottom: 2px;
            font-size: 12px;
          }

          .actual-value {
            font-size: 20px;
          }

          .widget-content {
            gap: 10px 8px;
            padding: 0 0 0.3rem;
          }

          .content-title, .content-value {
            font-size: 12px;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.skeleton = true;
    this._data = {};
  }

  render() {
    const headerTitle = this.localize(this._data.header?.title || '');
    const headerValue = this._data.header?.formattedValue || '';
    const headerPctChange = this._data.header?.formattedPctChange || '';
    const localizedTitle = this.localize(this._title);

    let accessibleContentText = '';
    const content = this._data.content?.map(obj => {
      const localizedLabel = obj.title.includes('companyName') ? this.localize(obj.title, { companyName: this.otherOptions.companyName }) : this.localize(obj.title);
      accessibleContentText += `${localizedLabel} ${obj.formattedValue}. `;
      return html`
        <div class="d2l-body-small left content-title">${localizedLabel}</div>
        <div class="d2l-body-compact right content-value">${obj.formattedValue}</div>
      `;
    }) || nothing;

    const accessibleText = `
      ${localizedTitle}.
      ${headerValue ? `${headerValue} ${headerTitle}.` : ''}
      ${headerPctChange ? `${headerPctChange} change.` : ''}
      ${accessibleContentText}
    `;

    return html`
      <d2l-card align-center tabindex="1" text=${accessibleText} class="widget d2l-skeletize">
        <div slot="header" class="widget-header d2l-skeletize" aria-hidden="true">
          <div class="header-top">
            ${this._renderIcon()}
            <h3 class="header-title">${localizedTitle}</h3>
          </div>
          <div class="header-bottom">
            <div class="d2l-body-compact left sub-header-title">${headerTitle}</div>
            <div class="right">
              <div class="d2l-body-small pct-change">${headerPctChange}</div>
              <h2 class="actual-value">${headerValue}</h2>
            </div>
          </div>
        </div>
        <div slot="content" class="widget-content" aria-hidden="true">
          ${content}
        </div>
      </d2l-card>
    `;
  }

  _renderIcon() {
    if (this.customIcon) {
      const src = `../../../assets/img/${this.customIcon}.svg`;
      return html`<d2l-icon-custom size="tier2"><img src=${src} alt=""></d2l-icon-custom>`;
    } else {
      return html`<d2l-icon icon=${this.icon}></d2l-icon>`;
    }
  }
}

window.customElements.define('report-widget', ReportWidget);
